import React from 'react';
import * as Styled from './input.styles';

const Input = React.forwardRef((props, ref) => (
    <Styled.Container>
        <Styled.Element ref={ref} {...props} autoComplete='off' />
    </Styled.Container>
));

export default Input;
