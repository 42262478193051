import { Route, Routes } from 'react-router-dom';

import background from './background.webp';
import { FirstSection } from '../../shared/components/first-section';
import { AQAServicesForm, CaseStudies, ScrollToTop, SecondSection } from '../../shared/components';
import { ICON_TYPES } from '../../shared/ui-kit';
import { Helmet } from 'react-helmet';

const content = [
    {
        title: 'Project scope',
        description:
            'Enhancing the QA process for the development team building a web store selling physical and digital books and connected by a workflow automation platform with order production, fulfillment and shipping facilities.',
        icon: '',
    },
    {
        title: 'The challenge',
        description:
            'The product team was required to improve the feature delivery process and avoid UI and functional issues. The average build delivery took 2 weeks, with 1.5-2 weeks for development and one week for testing. Each testing scope included more than 200 test cases for functional and UI parts and 4 test environments (desktop and mobile browsers).',
        icon: '',
    },
    {
        title: 'The solution',
        description:
            'Building an automation test solution that was easy to implement, included tests for API, UI, and e2e flow, and could be used for different browsers and screen resolutions.',
        icon: '',
    },
    { title: 'Technologies', description: '', icon: [ICON_TYPES.cypressLogo] },
    {
        title: 'The results',
        description: [
            'Ensured early bug detection. The tests were executed automatically as soon as the new feature code was merged into the project codebase.',
            'Reduced the time and effort spent on the regression testing process by ten times: 1 day for tests and results analysis, instead of a 1 week-job for 2 manual testers.',
            'Improved the API test and ensured that the application receives all the data and, in case of negative scenarios, handles all the errors',
            'Achieved 100% test coverage of the checkout page for negative and positive scenarios',
        ],
        icon: '',
    },
];

const CaseStudy1 = () => {
    return (
        <Routes>
            <Route
                path='/'
                element={
                    <>
                        <Helmet>
                            <title>Improving Quality Assurance for a web bookstore</title>
                            <meta itemprop='name' content='Improving Quality Assurance for a web bookstore' />
                            <meta name='og:title' content='Improving Quality Assurance for a web bookstore' />
                            <meta name='twitter:title' content='Improving Quality Assurance for a web bookstore' />
                            <meta
                                name='description'
                                content='Ensuring early bug detection, reducing the time & effort spent on QA, improving API tests and achieving thorough test coverage.'
                            />
                            <meta
                                itemprop='description'
                                content='Ensuring early bug detection, reducing the time & effort spent on QA, improving API tests and achieving thorough test coverage.'
                            />
                            <meta
                                name='og:description'
                                content='Ensuring early bug detection, reducing the time & effort spent on QA, improving API tests and achieving thorough test coverage.'
                            />
                            <meta
                                name='twitter:description'
                                content='Ensuring early bug detection, reducing the time & effort spent on QA, improving API tests and achieving thorough test coverage.'
                            />
                        </Helmet>
                        <ScrollToTop />
                        <FirstSection
                            background={background}
                            title='Improving Quality Assurance for a web bookstore'
                            description='Ensuring early bug detection, reducing the time & effort spent on QA, improving API tests and achieving thorough test coverage'
                            titleWidth={720}
                        />
                        <SecondSection content={content} />
                        <AQAServicesForm />
                        <CaseStudies title='Related Cases' cardId={1} />
                    </>
                }
            />
        </Routes>
    );
};

export default CaseStudy1;
