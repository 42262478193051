export const ICON_TYPES = {
    moneyBag: 'moneyBag',
    network: 'network',
    user: 'user',
    timer: 'timer',
    solved: 'solved',
    error: 'error',
    cypressLogo: 'cypressLogo',
    gitLabLogo: 'gitLabLogo',
    linkedInLogo: 'linkedInLogo',
    codex: 'codex',
    logo: 'logo',
    emailIcon: 'emailIcon',
    whitePhone: 'whitePhone',
    bluePhone: 'bluePhone',
    mail: 'mail',
    yahooMail: 'yahooMail',
    arrowNext: 'arrowNext',
    arrowBack: 'arrowBack',
    close: 'close',
    messages: 'messages',
    balance: 'balance',
    directions: 'directions',
    support: 'support',
    download: 'download',
};
