import * as Styled from './left-content.styles';
import { Icon, ICON_TYPES, Typography } from '../../../../ui-kit';

const contacts = [
    { icon: ICON_TYPES.emailIcon, contact: 'aqa@codex-soft.com' },
    { icon: ICON_TYPES.bluePhone, contact: '+351910394500' },
];

const LeftContent = () => {
    return (
        <Styled.Container>
            <Typography type='h3'>Need AQA services for your project?</Typography>
            <Styled.ContactsContainer>
                {contacts.map(({ icon, contact }) => (
                    <Styled.ContactBlock key={contact}>
                        <Icon type={icon} />
                        <Styled.Contact type='h6'>{contact}</Styled.Contact>
                    </Styled.ContactBlock>
                ))}
            </Styled.ContactsContainer>
        </Styled.Container>
    );
};

export default LeftContent;
