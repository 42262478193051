import { useState } from 'react';
import { Typography } from '../../../../../../shared/ui-kit';
import * as Styled from './PackageAccordionItem.styles';
import CaretDownIcon from '../../../../../../assets/images/caret-down-icon.svg';

const PackageAccordionItem = ({ title, pros, team, duration }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <Styled.Wrapper>
            <Styled.Toggle onClick={() => setIsActive(!isActive)}>
                <Typography type='h6'>{title}</Typography>
                <Styled.ToggleIcon width={24} height={24} src={CaretDownIcon} isActive={isActive} alt='toggle' />
            </Styled.Toggle>
            <Styled.Content isActive={isActive}>
                {pros &&
                    pros.items.map((pro) => (
                        <Styled.ContentItem key={pro}>
                            <img width={24} height={24} src={pros.icon} alt={pro} />
                            <Styled.ContentItemTitle>{pro}</Styled.ContentItemTitle>
                        </Styled.ContentItem>
                    ))}
                {team && (
                    <>
                        <Styled.ContentItemHeading type='h6'>Team</Styled.ContentItemHeading>
                        <Styled.ContentItem>
                            <img width={24} height={24} src={team.icon} alt={team.title} />
                            <Styled.ContentItemTitle>{team.title}</Styled.ContentItemTitle>
                        </Styled.ContentItem>
                    </>
                )}
                {duration && (
                    <>
                        <Styled.ContentItemHeading type='h6'>Duration</Styled.ContentItemHeading>
                        <Styled.ContentItem>
                            <img width={24} height={24} src={duration.icon} alt={duration.title} />
                            <Styled.ContentItemTitle>{duration.title}</Styled.ContentItemTitle>
                        </Styled.ContentItem>
                    </>
                )}
            </Styled.Content>
        </Styled.Wrapper>
    );
};

export default PackageAccordionItem;
