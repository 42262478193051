import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { NavigateContextProvider, ToastProvider } from './shared/helpers';

const rootElement = document.getElementById('root');
const RootComponent = (
    <React.StrictMode>
        <Router>
            <NavigateContextProvider>
                <ToastProvider>
                    <App />
                </ToastProvider>
            </NavigateContextProvider>
        </Router>
    </React.StrictMode>
);
if (rootElement.hasChildNodes()) {
    ReactDOM.hydrateRoot(rootElement, RootComponent);
} else {
    const root = ReactDOM.createRoot(rootElement);
    root.render(RootComponent);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
