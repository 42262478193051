const ArrowBack = (props) => {
    return (
        <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M14.4147 18.723L8.20276 12.5171C8.12903 12.4433 8.07693 12.3632 8.04645 12.277C8.01548 12.1909 8 12.0985 8 12C8 11.9015 8.01548 11.8091 8.04645 11.723C8.07693 11.6368 8.12903 11.5567 8.20276 11.4828L14.4147 5.25858C14.5868 5.08619 14.8018 5 15.0599 5C15.318 5 15.5392 5.09235 15.7235 5.27704C15.9078 5.46174 16 5.67722 16 5.92348C16 6.16974 15.9078 6.38522 15.7235 6.56992L10.3041 12L15.7235 17.4301C15.8955 17.6025 15.9816 17.8147 15.9816 18.0669C15.9816 18.3196 15.8894 18.5383 15.7051 18.723C15.5207 18.9077 15.3057 19 15.0599 19C14.8141 19 14.5991 18.9077 14.4147 18.723Z'
                fill='#64A4D5'
            />
        </svg>
    );
};

export default ArrowBack;
