import styled from 'styled-components';
import { Typography } from '../../../shared/ui-kit';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 75px 0;
    background: rgba(100, 164, 213, 0.24);
`;

export const Title = styled(Typography)`
    margin-bottom: 32px;
`;

export const Testimonial = styled.div`
    padding: 32px;
    background: #fff;
    border-radius: 12px;
    max-width: 700px;
    box-sizing: border-box;
    margin: 0 20px;
`;

export const TestimonialText = styled(Typography)``;

export const TestimonialAuthor = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 32px;
`;

export const Avatar = styled.img`
    width: 52px;
    height: 52px;
    border-radius: 50%;
`;

export const AuthorName = styled(Typography)``;
