import styled from 'styled-components';
import { Typography } from '../../ui-kit';
import { NavLink } from 'react-router-dom';

export const Cards = styled.div`
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    margin-top: 32px;
    flex-wrap: wrap;

    @media (max-width: 1240px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

export const Card = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 384px;
    height: 440px;
    box-shadow: 0 24px 48px rgba(0, 0, 0, 0.12);
    border-radius: 12px;

    @media (max-width: 1240px) {
        width: 360px;
    }

    @media (max-width: 420px) {
        width: 335px;
    }
`;

export const Background = styled.div`
    background: url(${({ background }) => background});
    width: 100%;
    height: 265px;
    background-size: cover;
    border-radius: 12px 12px 0 0;
`;

export const DescriptionBlock = styled.div`
    padding: 32px;
    padding-bottom: 53px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: space-between;
    box-sizing: border-box;
`;

export const Description = styled(Typography)`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
`;

export const Link = styled(NavLink)`
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
        text-shadow: 1px 1px 0 lighten(#64a4d5, 15%), 2px 2px 0 lighten(#64a4d5, 10%), 3px 3px 0 lighten(#64a4d5, 5%);
        transform: translateY(-5px);
    }
`;

export const CompanyLogo = styled.img`
    margin-bottom: 16px;
`;
