import * as Styled from './ProvideQualityInfo.styles';
import { Typography } from '../../../../shared/ui-kit';
import ProvideQualityInfoImage from '../../../../assets/images/we-provide-quality-section.svg';

const Introduction = () => {
    return (
        <Styled.Wrapper>
            <Styled.LeftContent>
                <Typography type='h3'>
                    We provide quality assurance engineers for software projects of any complexity and scale.
                </Typography>
            </Styled.LeftContent>
            <Styled.RightContent>
                <img src={ProvideQualityInfoImage} alt='ProvideQualityInfoImage' />
            </Styled.RightContent>
        </Styled.Wrapper>
    );
};

export default Introduction;
