import styled from 'styled-components';
import { Typography } from '../../ui-kit';
import { NavLink } from 'react-router-dom';

export const Container = styled.section`
    background: url(${({ background }) => background});
    background-size: cover;
    height: 566px;

    @media (max-width: 1240px) {
        padding: 0 20px;
        box-sizing: border-box;
    }
`;

export const IntroductionContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1200px;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    @media (max-width: 767px) {
        align-items: flex-start;
    }
`;

export const TitleBlock = styled.div`
    margin-top: 12px;
    width: ${({ width }) => `${width}px`};

    @media (max-width: 1024px) {
        width: auto;
    }
`;

export const DescriptionBlock = styled.div`
    margin-top: 24px;
    width: 620px;

    @media (max-width: 767px) {
        width: auto;
    }
`;

export const Description = styled(Typography)`
    color: #ffffff;
`;
export const Link = styled(NavLink)`
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;
    width: fit-content;
    transition: 0.2s cubic-bezier(0.54, 1.26, 0.61, 0.87);
    overflow-x: clip;
    &:hover {
        margin-left: -5px;
        & > *:last-child {
            margin-left: 5px;
        }
        &:after {
            right: 0;
        }
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -5px;
        right: -100%;
        width: 100%;
        height: 2px;
        background: #64a4d5;
        transition: 0.2s cubic-bezier(0.54, 1.26, 0.61, 0.87);
    }
`;

export const BreadCrumb = styled.div`
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
`;

export const BreadCrumbLink = styled(NavLink)`
    color: inherit;
    text-decoration: none;
    margin-right: 5px;
`;

export const LinkText = styled(Typography)`
    display: inline;
    margin-left: 5px;
    color: rgba(255, 255, 255, 0.87);
    transition: 0.2s cubic-bezier(0.54, 1.26, 0.61, 0.87);
`;
