import { useContext } from 'react';

import * as Styled from './aqa-services-form.styles';
import { LeftContent } from './components/left-content';
import { RightContent } from './components/right-content';
import { NavigateContext } from '../../helpers';

const AQAServicesForm = () => {
    const { contactUsRef } = useContext(NavigateContext);

    return (
        <Styled.QAQServiceContainer ref={contactUsRef}>
            <Styled.QAQServiceContent>
                <LeftContent />
                <RightContent />
            </Styled.QAQServiceContent>
        </Styled.QAQServiceContainer>
    );
};

export default AQAServicesForm;
