import * as Styled from './left-content.styles';
import { ReactComponent as CheckIcon } from '../../../../../../assets/images/check.svg';

const ListItems = [
    'Want to reduce time & cost spent on development?',
    'Struggling with talent acquisition and HR management?',
    'Need a solid tech backup at a key phase?',
    'Looking for guidance or rare tech knowledge?',
];

const LeftContent = () => {
    return (
        <Styled.Container>
            <Styled.Title type='h3'>
                We’ll find a solution.
                <br /> Get in touch today!
            </Styled.Title>
            <Styled.List>
                {ListItems.map((item, index) => (
                    <Styled.ListItem key={index}>
                        <CheckIcon />
                        <Styled.ListItemTitle>{item}</Styled.ListItemTitle>
                    </Styled.ListItem>
                ))}
            </Styled.List>
        </Styled.Container>
    );
};

export default LeftContent;
