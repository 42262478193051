import { useContext } from 'react';

import * as Styled from './main-introduction.styles';
import { ICON_TYPES, Typography } from '../../../../shared/ui-kit';
import { NavigateContext } from '../../../../shared/helpers';

const Introduction = () => {
    const { scrollToBookCallForm } = useContext(NavigateContext);

    return (
        <Styled.IntroductionContainer>
            <Styled.IntroductionContent>
                <Styled.TitleBlock>
                    <Typography type='h1'>Automated Quality Assurance Services</Typography>
                </Styled.TitleBlock>
                <Styled.Advantages>
                    Speed up testing • Reduce time-to-market • Increase product quality
                </Styled.Advantages>
                <Styled.DescriptionBlock>
                    <Styled.Description>
                        Automated testing (AQA) means using software tools to control the execution of tests, automating
                        repetitive (or too complex) manual tasks and analyzing the outcomes. A critical component of
                        Continues Delivery is using automated QA tools, which provide significant time savings and
                        increases the quality of the end product.
                    </Styled.Description>
                </Styled.DescriptionBlock>
                <Styled.BookCallButton icon={ICON_TYPES.whitePhone} onClick={scrollToBookCallForm}>
                    <Styled.BookCallButtonText>Book a call</Styled.BookCallButtonText>
                </Styled.BookCallButton>
            </Styled.IntroductionContent>
        </Styled.IntroductionContainer>
    );
};

export default Introduction;
