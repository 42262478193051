const IconClose = (props) => {
    return (
        <svg {...props} viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M14.856 6.46447L11.3205 10L14.856 13.5355C15.1801 13.8596 15.1801 14.39 14.856 14.714C14.5319 15.0381 14.0016 15.0381 13.6775 14.714L10.142 11.1785L6.60642 14.714C6.28233 15.0381 5.752 15.0381 5.42791 14.714C5.10382 14.39 5.10382 13.8596 5.42791 13.5355L8.96345 10L5.42791 6.46447C5.10382 6.14038 5.10382 5.61005 5.42791 5.28596C5.752 4.96186 6.28233 4.96186 6.60642 5.28596L10.142 8.82149L13.6775 5.28596C14.0016 4.96187 14.5319 4.96187 14.856 5.28596C15.1801 5.61005 15.1801 6.14038 14.856 6.46447Z'
                fill='#2C3E66'
            />
        </svg>
    );
};

export default IconClose;
