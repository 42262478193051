import * as Styled from './first-section.styles';
import { Typography } from '../../ui-kit';

const FirstSection = ({ background = '', title = '', description = '', titleWidth = 0 }) => {
    return (
        <Styled.Container background={background}>
            <Styled.IntroductionContent>
                <Styled.BreadCrumb>
                    <Styled.BreadCrumbLink to={'/'}>Home</Styled.BreadCrumbLink>/
                    <Styled.LinkText>Case Study</Styled.LinkText>
                </Styled.BreadCrumb>
                <Styled.TitleBlock width={titleWidth}>
                    <Typography type='h1'>{title}</Typography>
                </Styled.TitleBlock>
                <Styled.DescriptionBlock>
                    <Styled.Description>{description}</Styled.Description>
                </Styled.DescriptionBlock>
            </Styled.IntroductionContent>
        </Styled.Container>
    );
};

export default FirstSection;
