import { useContext } from 'react';

import * as Styled from './introduction.styles';
import { Typography } from '../../../../shared/ui-kit';
import { NavigateContext } from '../../../../shared/helpers';

const Introduction = () => {
    const { scrollToFindSolutionForm } = useContext(NavigateContext);

    return (
        <Styled.IntroductionContainer>
            <Styled.IntroductionContent>
                <Styled.TitleBlock>
                    <Typography type='h1'>Hire Automated Quality Assurance Engineers</Typography>
                </Styled.TitleBlock>
                <Styled.Advantages>
                    Speed up testing. Reduce time-to-market. Increase product quality.
                </Styled.Advantages>
                <Styled.RequestAQuote onClick={scrollToFindSolutionForm}>
                    <Styled.RequestAQuoteText>Request a quote</Styled.RequestAQuoteText>
                </Styled.RequestAQuote>
            </Styled.IntroductionContent>
        </Styled.IntroductionContainer>
    );
};

export default Introduction;
