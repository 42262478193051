const SupportIcon = (props) => {
    return (
        <svg {...props} width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_1112_866)'>
                <path
                    d='M47.9999 21.0858V15.4449H45.6872C45.5211 14.9157 45.3085 14.4031 45.0509 13.9109L46.6867 12.2752L42.6983 8.28698L41.0624 9.92282C40.5697 9.66501 40.0567 9.45229 39.5277 9.28635V6.97363H33.8877V9.28635C33.3587 9.45238 32.8457 9.66501 32.353 9.92282L30.7171 8.28698L26.7288 12.2752L28.3646 13.911C28.1075 14.4028 27.8951 14.9153 27.7292 15.4449H25.4155V21.0858H27.7291C27.895 21.6153 28.1075 22.128 28.3647 22.6199L26.729 24.2567L30.7173 28.2449L32.3532 26.608C32.8459 26.8657 33.3588 27.0784 33.8877 27.2444V29.558H39.5277V27.2444C40.0567 27.0783 40.5695 26.8657 41.0622 26.608L42.6982 28.2449L46.6865 24.2567L45.0508 22.6199C45.3085 22.1276 45.5211 21.6149 45.6872 21.0858H47.9999ZM36.7077 22.9725C34.108 22.9725 32.0015 20.865 32.0015 18.2663C32.0015 15.6666 34.108 13.5591 36.7077 13.5591C39.3074 13.5591 41.414 15.6666 41.414 18.2663C41.414 20.865 39.3074 22.9725 36.7077 22.9725Z'
                    fill='#BCDBF4'
                />
                <path
                    d='M40.0063 32.0094C39.5558 32.0091 39.9623 31.991 31.0308 32.9272C31.1459 31.9023 30.7631 30.9048 30.1331 30.2922C30.0788 30.238 28.5404 28.9811 23.319 24.6959C20.5905 19.9016 16.7621 18.4842 14.0168 18.1424C11.2476 17.7978 8.66119 18.3999 7.00219 19.1279C6.01763 18.0787 4.62 17.4214 3.07106 17.4214H0.9375C0.419719 17.4214 0 17.8412 0 18.3589V34.3614C0 34.879 0.419719 35.2989 0.9375 35.2989H3.07116C4.69453 35.2989 6.15159 34.5769 7.14113 33.4383C9.11897 33.5784 10.9635 34.4169 12.3753 35.8287C15.3584 38.8114 17.9783 41.0265 22.0978 41.0262C22.7451 41.0262 23.4324 40.9691 24.1599 40.8434L44.3641 38.1142C44.8292 38.0514 45.1761 37.6544 45.1761 37.1851C45.176 34.3312 42.8569 32.0094 40.0063 32.0094ZM3.07116 33.4238H1.875V19.2963H3.07116C5.01103 19.2963 6.58931 20.8745 6.58931 22.8143V29.9057C6.58931 31.8456 5.01103 33.4238 3.07116 33.4238ZM23.8914 38.9874C23.8796 38.9891 23.8678 38.9909 23.8561 38.993C18.0578 40.0031 15.577 35.9152 13.6524 34.4544C12.1408 32.9627 10.2323 32.0006 8.16656 31.6697C8.35866 31.1166 8.46431 30.5234 8.46431 29.9058V22.8144C8.46431 22.0747 8.31441 21.3695 8.04375 20.7271C9.34294 20.2142 11.4476 19.7116 13.7852 20.0032C17.1947 20.4276 19.8806 22.3658 21.7685 25.7639L21.7721 25.7619C21.9397 26.0515 21.5292 25.6325 28.8303 31.6428C29.3093 32.1675 29.2991 32.9944 28.7999 33.5068C28.4224 33.8946 27.8603 33.9957 27.3835 33.7678C22.5991 30.147 22.596 30.1487 20.3336 28.4321C19.9808 28.1616 19.474 28.1737 19.1334 28.4833C18.7498 28.8321 18.7225 29.4251 19.0703 29.8076C19.1603 29.9065 18.7281 29.569 26.3171 35.3123C26.5704 35.5041 27.2224 35.7669 27.9157 35.7668C28.6861 35.7668 29.4447 35.4752 30.0394 34.9164C41.7241 33.6941 38.0108 34.0569 40.0062 33.8846C41.5455 33.8846 42.8416 34.9475 43.2016 36.379L23.8914 38.9874Z'
                    fill='#1E274F'
                />
                <path
                    d='M36.7077 23.91C39.8197 23.91 42.3515 21.3782 42.3515 18.2663C42.3515 15.1538 39.8197 12.6216 36.7077 12.6216C33.5958 12.6216 31.064 15.1538 31.064 18.2663C31.064 21.3782 33.5957 23.91 36.7077 23.91ZM36.7077 14.4966C38.7858 14.4966 40.4765 16.1876 40.4765 18.2663C40.4765 20.3443 38.7858 22.035 36.7077 22.035C34.6297 22.035 32.939 20.3443 32.939 18.2663C32.939 16.1876 34.6297 14.4966 36.7077 14.4966Z'
                    fill='#1E274F'
                />
            </g>
            <defs>
                <clipPath id='clip0_1112_866'>
                    <rect width='48' height='48' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SupportIcon;
