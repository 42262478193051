import styled from 'styled-components';
import { Icon, Typography } from '../../ui-kit';

export const Footer = styled.footer`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    background-color: #171a1d;

    @media (max-width: 1240px) {
        padding: 0 20px;
        box-sizing: border-box;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Label = styled(Typography)`
    color: #64a4d5;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0;
    margin-left: 10px;
`;

export const SocialMediaContainer = styled.div`
    position: relative;
`;

export const SocialMediaIcon = styled(Icon)`
    cursor: pointer;
    transition: 0.2s cubic-bezier(0.54, 1.26, 0.61, 0.87);
    margin-left: 12px;
    &:first-child: {
        margin-left: 0;
    }

    &:hover {
        filter: brightness(0.8);
    }
`;
