import styled from 'styled-components';
import background from '../../../../assets/images/first-section-background.png';
import { Button, Typography } from '../../../../shared/ui-kit';

export const IntroductionContainer = styled.section`
    background: url(${background});
    background-size: cover;
    height: calc(100vh - 80px);

    @media (max-width: 1240px) {
        padding: 0 20px;
        box-sizing: border-box;
    }

    @media (max-width: 767px) {
        padding: 69px 20px 38px 20px;
        height: 100%;
    }
`;

export const IntroductionContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
`;

export const TitleBlock = styled.div`
    width: 763px;
    text-align: center;
    p {
        font-weight: 500;
    }

    @media (max-width: 1024px) {
        width: auto;
    }
`;

export const Advantages = styled(Typography)`
    margin-top: 12px;
    color: white;
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    text-align: center;
    font-family: 'SF Pro Display', sans-serif;
`;

export const Description = styled(Typography)`
    color: #ffffff;
`;

export const RequestAQuote = styled(Button)`
    margin-top: 48px;
    width: 210px;
    height: 64px;
    background: white;
    color: #1e274f;
    filter: drop-shadow(0px 24px 48px rgba(188, 219, 244, 0.12));
    &:hover {
        background: white;
        color: #1e274f;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
    }

    @media (max-width: 479px) {
        width: 335px;
    }
`;
export const RequestAQuoteText = styled(Typography)`
    line-height: 24px;
`;
