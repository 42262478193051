import styled from 'styled-components';
import { Button, Input, Typography } from '../../ui-kit';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Form = styled.form`
    background: #dae9f5;
    border-radius: 12px;
    padding: 48px;

    @media screen and (max-width: 479px) {
        padding: 48px 24px;
    }
`;

export const MainTitle = styled(Typography)`
    margin-bottom: 12px;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.05em;
    font-weight: 500;
    text-align: center;
`;

export const SubTitle = styled(Typography)`
    margin-bottom: 24px;
    text-align: center;
    letter-spacing: 0.05em;
`;

export const FieldBlock = styled.div`
    position: relative;
`;

export const FieldInput = styled(Input)`
    margin-top: 24px;

    @media (max-width: 479px) {
        width: 335px;
    }
`;

export const SubmitButton = styled(Button)`
    width: 418px;
    height: 64px;
    margin-top: 48px;
    @media (max-width: 479px) {
        width: 335px;
    }
`;

export const SubmitButtonText = styled(Typography)`
    color: #ffffff;
    letter-spacing: 0;
`;
