const Logo = (props) => {
    return (
        <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M12 22C10.6333 22 9.34167 21.7373 8.125 21.212C6.90833 20.6873 5.846 19.9707 4.938 19.062C4.02933 18.154 3.31267 17.0917 2.788 15.875C2.26267 14.6583 2 13.3667 2 12C2 10.6167 2.26267 9.321 2.788 8.113C3.31267 6.90433 4.02933 5.846 4.938 4.938C5.846 4.02933 6.90833 3.31233 8.125 2.787C9.34167 2.26233 10.6333 2 12 2C13.3833 2 14.679 2.26233 15.887 2.787C17.0957 3.31233 18.154 4.02933 19.062 4.938C19.9707 5.846 20.6873 6.90433 21.212 8.113C21.7373 9.321 22 10.6167 22 12V13.45C22 14.4333 21.6627 15.2707 20.988 15.962C20.3127 16.654 19.4833 17 18.5 17C17.9 17 17.3417 16.8667 16.825 16.6C16.3083 16.3333 15.8833 15.9833 15.55 15.55C15.1 16 14.571 16.3543 13.963 16.613C13.3543 16.871 12.7 17 12 17C10.6167 17 9.43767 16.5123 8.463 15.537C7.48767 14.5623 7 13.3833 7 12C7 10.6167 7.48767 9.43733 8.463 8.462C9.43767 7.48733 10.6167 7 12 7C13.3833 7 14.5627 7.48733 15.538 8.462C16.5127 9.43733 17 10.6167 17 12V13.45C17 13.9333 17.15 14.3123 17.45 14.587C17.75 14.8623 18.1 15 18.5 15C18.9 15 19.25 14.8623 19.55 14.587C19.85 14.3123 20 13.9333 20 13.45V12C20 9.81667 19.2123 7.93733 17.637 6.362C16.0623 4.78733 14.1833 4 12 4C9.81667 4 7.93733 4.78733 6.362 6.362C4.78733 7.93733 4 9.81667 4 12C4 14.1833 4.78733 16.0623 6.362 17.637C7.93733 19.2123 9.81667 20 12 20H17V22H12ZM12 15C12.8333 15 13.5417 14.7083 14.125 14.125C14.7083 13.5417 15 12.8333 15 12C15 11.1667 14.7083 10.4583 14.125 9.875C13.5417 9.29167 12.8333 9 12 9C11.1667 9 10.4583 9.29167 9.875 9.875C9.29167 10.4583 9 11.1667 9 12C9 12.8333 9.29167 13.5417 9.875 14.125C10.4583 14.7083 11.1667 15 12 15Z'
                fill='#64A4D5'
            />
        </svg>
    );
};

export default Logo;
