import styled from 'styled-components';
import { Typography } from '../../../../shared/ui-kit';

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 150px 0;
    background-color: #f5f5f5;

    @media (max-width: 1240px) {
        padding: 150px 20px;
        box-sizing: border-box;
    }
    @media screen and (max-width: 520px) {
        padding: 75px 20px;
    }
`;
export const TitleBlock = styled.div`
    width: 760px;
    text-align: center;
    margin-bottom: 32px;

    @media (max-width: 1240px) {
        width: 720px;
    }

    @media (max-width: 767px) {
        width: auto;
    }

    @media (max-width: 420px) {
        width: 335px;
    }
`;

export const Cards = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 24px;
    max-width: 1200px;
    width: auto;
    height: 100%;
    margin: 0 auto;

    @media (max-width: 1240px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
`;

export const Card = styled.div`
    border-radius: 8px;
    width: 384px;
    padding: 32px;
    box-sizing: border-box;
    box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.12);
    transition: 0.2s linear;
    font-family: 'SF Pro Display', sans-serif;

    &:hover {
        transform: translateY(-5px);
    }

    @media (max-width: 1240px) {
        width: 360px;
    }

    @media (max-width: 420px) {
        width: 335px;
    }
`;

export const CardTitle = styled(Typography)`
    margin-top: 24px;
    letter-spacing: 0.05em;
`;

export const CardDescription = styled(CardTitle)`
    margin-top: 12px;
    width: 320px;
    letter-spacing: 0;

    @media (max-width: 1240px) {
        width: auto;
    }
`;
