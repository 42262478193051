import styled from 'styled-components';

export const CaseStudiesContainer = styled.section`
    padding: 75px 0;
    box-sizing: border-box;

    @media (max-width: 1240px) {
        padding: 150px 20px;
    }

    @media screen and (max-width: 520px) {
        padding: 75px 20px;
    }
`;

export const CaseStudiesContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: auto;
    margin: 0 auto;
`;
