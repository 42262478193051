import styled from 'styled-components';
import { Button, Typography } from '../../../../shared/ui-kit';

export const Container = styled.div`
    padding: 150px 0 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 520px) {
        padding: 75px 0 35px;
    }
`;

export const MainTitle = styled(Typography)`
    margin-bottom: 32px;
`;

export const PackagesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
    align-items: flex-start;
`;

export const Package = styled.div`
    display: flex;
    flex-direction: column;
    width: 384px;
    padding: 32px 32px 24px;
    border: 1px solid #bcdbf4;
    border-radius: 8px;
    box-sizing: border-box;

    @media screen and (max-width: 520px) {
        padding: 24px 24px 16px;
        min-width: 320px;
        width: 100%;
        max-width: 384px;
        margin: 0 10px;
    }
`;

export const PackageIcon = styled.img`
    max-width: 48px;
    margin-bottom: 32px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 254px;
    justify-content: center;
`;

export const PackageTitle = styled(Typography)`
    max-width: calc(100% - 55px);
    font-size: 24px;
`;

export const PackageDescription = styled(Typography)`
    margin-top: 12px;
`;

export const PackageAccordion = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PackageButton = styled(Button)`
    width: 100%;
    margin-top: 24px;
`;

export const PackageButtonText = styled(Typography)`
    color: #fff;
`;
