import * as Styled from './packages.styles';
import { PackageAccordionItem } from './components/PackageAccordionItem';
import { packages } from './packages-info';
import { useContext } from 'react';
import { NavigateContext } from '../../../../shared/helpers';

const Packages = () => {
    const { scrollToBookCallForm } = useContext(NavigateContext);

    return (
        <Styled.Container>
            <Styled.MainTitle type='h3'>Packages</Styled.MainTitle>
            <Styled.PackagesWrapper>
                {packages.map(({ icon, title, description, accordion }) => (
                    <Styled.Package key={title}>
                        <Styled.PackageIcon width={48} height={48} src={icon} alt={title} />
                        <Styled.TitleWrapper>
                            <Styled.PackageTitle type='h6'>{title}</Styled.PackageTitle>
                            <Styled.PackageDescription>{description}</Styled.PackageDescription>
                        </Styled.TitleWrapper>
                        <Styled.PackageAccordion>
                            {accordion.map(({ title, pros, team, duration }) => (
                                <PackageAccordionItem
                                    key={title}
                                    title={title}
                                    pros={pros}
                                    team={team}
                                    duration={duration}
                                />
                            ))}
                        </Styled.PackageAccordion>
                        <Styled.PackageButton onClick={scrollToBookCallForm}>
                            <Styled.PackageButtonText>Talk to an expert</Styled.PackageButtonText>
                        </Styled.PackageButton>
                    </Styled.Package>
                ))}
            </Styled.PackagesWrapper>
        </Styled.Container>
    );
};

export default Packages;
