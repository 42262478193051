import { Route, Routes } from 'react-router-dom';

import background from './background.webp';
import { FirstSection } from '../../shared/components/first-section';
import { AQAServicesForm, CaseStudies, ScrollToTop, SecondSection } from '../../shared/components';
import { ICON_TYPES } from '../../shared/ui-kit';
import { Helmet } from 'react-helmet';

const content = [
    {
        title: 'Project scope',
        description:
            'A web-based behavioral health platform that performs an assessment for a patient, and, based on assessment, calculates the treatment and sets agreements with healthcare service providers.',
        icon: '',
    },
    {
        title: 'The challenge',
        description:
            'No testing techniques could be implemented to reduce the quantity of e2e cases, and each assessment had to be tested after every release. The first release has shown that the manual quality assurance takes 1,5 weeks to validate all combinations with assessments.',
        additionalDescription:
            'The AQA team therefore received a task to deliver the software solution that automates the assessment process, validates treatments based on results, and eliminates human mistakes in manual testing processes.',
        icon: '',
    },
    {
        title: 'The solution',
        description:
            'The engineering team created automated test cases for all the components related to the treatment assessment.',
        icon: '',
    },
    { title: 'Technologies', description: '', icon: [ICON_TYPES.cypressLogo] },
    {
        title: 'The results',
        description: [
            'The provided solution ensures that the treatment calculations are correct in each release.',
            'The necessary involvement of manual QA engineers was reduced from 1,5 weeks to 3 days.',
        ],
        icon: '',
    },
];

const CaseStudy3 = () => {
    return (
        <Routes>
            <Route
                path='/'
                element={
                    <>
                        <Helmet>
                            <title>Improving the Quality Assurance of a healthcare platform project</title>
                            <meta
                                itemprop='name'
                                content='Improving the Quality Assurance of a healthcare platform project'
                            />
                            <meta
                                name='og:title'
                                content='Improving the Quality Assurance of a healthcare platform project'
                            />
                            <meta
                                name='twitter:title'
                                content='Improving the Quality Assurance of a healthcare platform project'
                            />
                            <meta name='description' content='Improving accuracy and reducing the time spent on QA.' />
                            <meta
                                itemprop='description'
                                content='Improving accuracy and reducing the time spent on QA.'
                            />
                            <meta
                                name='og:description'
                                content='Improving accuracy and reducing the time spent on QA.'
                            />
                            <meta
                                name='twitter:description'
                                content='Improving accuracy and reducing the time spent on QA.'
                            />
                        </Helmet>
                        <ScrollToTop />
                        <FirstSection
                            background={background}
                            title='Improving the Quality Assurance of a healthcare platform project'
                            description='Improving accuracy and reducing the time spent on QA'
                            titleWidth={1000}
                        />
                        <SecondSection content={content} />
                        <AQAServicesForm />
                        <CaseStudies title='Related cases' cardId={3}></CaseStudies>
                    </>
                }
            />
        </Routes>
    );
};

export default CaseStudy3;
