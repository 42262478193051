const Solved = (props) => {
    return (
        <svg {...props} width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_765_3364)'>
                <path
                    d='M43.2972 16.9438C42.7795 16.9438 42.3597 17.3635 42.3597 17.8813V44.2385C42.3597 45.2787 41.5135 46.125 40.4733 46.125H3.76153C2.72128 46.125 1.875 45.2787 1.875 44.2385V13.1709H28.4242C28.9419 13.1709 29.3617 12.7511 29.3617 12.2334C29.3617 11.7158 28.9419 11.2959 28.4242 11.2959H1.875V7.52673C1.875 6.48648 2.72128 5.64029 3.76153 5.64029H30.1186C30.6364 5.64029 31.0561 5.22048 31.0561 4.70279C31.0561 4.18501 30.6364 3.76529 30.1186 3.76529H3.76153C1.68741 3.76529 0 5.4527 0 7.52673V44.2385C0 46.3126 1.68741 48 3.76153 48H40.4733C42.5473 48 44.2347 46.3126 44.2347 44.2385V17.8813C44.2347 17.3636 43.8149 16.9438 43.2972 16.9438V16.9438Z'
                    fill='#1E274F'
                />
                <path
                    d='M4.98103 7.80527C4.61578 8.17042 4.6154 8.76574 4.98094 9.13127C5.34609 9.49642 5.9415 9.49671 6.30694 9.13127C6.67209 8.76611 6.67247 8.1708 6.30703 7.80527C5.94178 7.44002 5.34656 7.43964 4.98103 7.80527V7.80527Z'
                    fill='#1E274F'
                />
                <path
                    d='M10.3509 9.40586C10.8688 9.40586 11.2885 8.98608 11.2885 8.46826C11.2885 7.95045 10.8688 7.53067 10.3509 7.53067C9.83312 7.53067 9.41335 7.95045 9.41335 8.46826C9.41335 8.98608 9.83312 9.40586 10.3509 9.40586Z'
                    fill='#1E274F'
                />
                <path
                    d='M14.3942 7.80516C14.0289 8.17032 14.0286 8.76563 14.3942 9.13116C14.7593 9.49632 15.3547 9.4966 15.7202 9.13125C16.0853 8.7661 16.0857 8.17078 15.7203 7.80525C15.3549 7.43991 14.7598 7.43963 14.3942 7.80516Z'
                    fill='#1E274F'
                />
                <path
                    d='M19.764 7.53058C19.2462 7.53058 18.8265 7.95039 18.8265 8.46808C18.8265 8.98577 19.2462 9.40558 19.764 9.40558H28.4243C28.9419 9.40558 29.3618 8.98577 29.3618 8.46808C29.3618 7.95039 28.9419 7.53058 28.4243 7.53058H19.764Z'
                    fill='#1E274F'
                />
                <path
                    d='M7.52671 41.4184H5.64412C5.12634 41.4184 4.70662 41.8382 4.70662 42.3559C4.70662 42.8736 5.12634 43.2934 5.64412 43.2934H7.52671C8.0444 43.2934 8.46421 42.8736 8.46421 42.3559C8.46421 41.8382 8.04449 41.4184 7.52671 41.4184Z'
                    fill='#1E274F'
                />
                <path
                    d='M37.6492 41.4184H12.2333C11.7156 41.4184 11.2958 41.8382 11.2958 42.3559C11.2958 42.8736 11.7156 43.2934 12.2333 43.2934H37.6491C38.1668 43.2934 38.5866 42.8736 38.5866 42.3559C38.5866 41.8382 38.1669 41.4184 37.6492 41.4184V41.4184Z'
                    fill='#1E274F'
                />
                <path
                    d='M7.52671 36.7118H5.64412C5.12634 36.7118 4.70662 37.1316 4.70662 37.6493C4.70662 38.1669 5.12634 38.5868 5.64412 38.5868H7.52671C8.0444 38.5868 8.46421 38.1669 8.46421 37.6493C8.46421 37.1316 8.04449 36.7118 7.52671 36.7118Z'
                    fill='#1E274F'
                />
                <path
                    d='M37.6492 36.7118H12.2333C11.7156 36.7118 11.2958 37.1316 11.2958 37.6493C11.2958 38.1669 11.7156 38.5868 12.2333 38.5868H37.6491C38.1668 38.5868 38.5866 38.1669 38.5866 37.6493C38.5866 37.1316 38.1669 36.7118 37.6492 36.7118V36.7118Z'
                    fill='#1E274F'
                />
                <path
                    d='M7.52671 32.0051H5.64412C5.12634 32.0051 4.70662 32.4249 4.70662 32.9426C4.70662 33.4603 5.12634 33.8801 5.64412 33.8801H7.52671C8.0444 33.8801 8.46421 33.4603 8.46421 32.9426C8.46421 32.4249 8.04449 32.0051 7.52671 32.0051Z'
                    fill='#1E274F'
                />
                <path
                    d='M37.6492 32.0051H12.2333C11.7156 32.0051 11.2958 32.4249 11.2958 32.9426C11.2958 33.4603 11.7156 33.8801 12.2333 33.8801H37.6491C38.1668 33.8801 38.5866 33.4603 38.5866 32.9426C38.5866 32.4249 38.1669 32.0051 37.6492 32.0051V32.0051Z'
                    fill='#1E274F'
                />
                <path
                    d='M7.52671 27.2985H5.64412C5.12634 27.2985 4.70662 27.7183 4.70662 28.236C4.70662 28.7537 5.12634 29.1735 5.64412 29.1735H7.52671C8.0444 29.1735 8.46421 28.7537 8.46421 28.236C8.46421 27.7183 8.04449 27.2985 7.52671 27.2985Z'
                    fill='#1E274F'
                />
                <path
                    d='M37.6492 27.2985H12.2333C11.7156 27.2985 11.2958 27.7183 11.2958 28.236C11.2958 28.7537 11.7156 29.1735 12.2333 29.1735H37.6491C38.1668 29.1735 38.5866 28.7537 38.5866 28.236C38.5866 27.7183 38.1669 27.2985 37.6492 27.2985V27.2985Z'
                    fill='#1E274F'
                />
                <path
                    d='M8.93878 16.0025C6.60515 16.0025 4.70662 17.901 4.70662 20.2346C4.70662 22.5683 6.60515 24.4668 8.93878 24.4668H35.2959C37.8915 24.4668 39.8813 22.1468 39.4773 19.5752C39.3969 19.0635 38.9166 18.7147 38.4056 18.7945C37.8941 18.8749 37.5445 19.3548 37.625 19.8662C37.8498 21.2966 36.7444 22.5918 35.2959 22.5918H8.93878C7.63912 22.5918 6.58162 21.5344 6.58162 20.2346C6.58162 18.9349 7.63903 17.8775 8.93878 17.8775H30.9281C31.4459 17.8775 31.8656 17.4577 31.8656 16.94C31.8656 16.4222 31.4459 16.0025 30.9281 16.0025H8.93878Z'
                    fill='#1E274F'
                />
                <path
                    d='M37.6492 20.7015C43.3658 20.7015 48 16.0673 48 10.3507C48 4.63419 43.3658 0 37.6492 0C31.9327 0 27.2985 4.63419 27.2985 10.3507C27.2985 16.0673 31.9327 20.7015 37.6492 20.7015Z'
                    fill='#BCDBF4'
                />
                <path
                    d='M35.339 13.9923C35.7053 14.3584 36.2986 14.3583 36.6649 13.9923L42.622 8.03513C42.9881 7.66904 42.9881 7.07541 42.622 6.70922C42.2558 6.34322 41.6624 6.34322 41.2961 6.70922L36.0019 12.0035L34.0023 10.0039C33.6361 9.63788 33.0427 9.63788 32.6764 10.0039C32.3103 10.37 32.3103 10.9636 32.6764 11.3298L35.339 13.9923Z'
                    fill='#1E274F'
                />
            </g>
            <defs>
                <clipPath id='clip0_765_3364'>
                    <rect width='48' height='48' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Solved;
