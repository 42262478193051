import styled from 'styled-components';

export const Container = styled.div``;

export const Element = styled.input`
    height: 64px;
    width: 418px;
    outline: none;
    margin: 0;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    border-radius: 8px;
    background-color: #ffffff;
    color: #212529;
    border: none;
    padding-left: 12px;
    box-sizing: border-box;
    transition: 0.2s cubic-bezier(0.54, 1.26, 0.61, 0.87);

    &::placeholder {
        color: rgba(163, 163, 163, 0.65);
        font-size: 16px;
    }

    &:hover {
        box-shadow: 0 0 0 3px #d8d8d8;
    }

    &:focus {
        box-shadow: 0 0 0 3px #64a4d5;
    }

    ${(props) =>
        props.isError &&
        `
        box-shadow: 0 0 1px 2px crimson !important;
    `}
`;
