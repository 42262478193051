import styled from 'styled-components';

export const QAQServiceContainer = styled.section`
    background-color: #64a4d53d;
    padding: 74px 20px;
    box-sizing: border-box;
`;

export const QAQServiceContent = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    width: auto;
    margin: 0 auto;

    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
    }
`;
