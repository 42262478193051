import styled from 'styled-components';

export const Container = styled.div`
    padding: 108px 20px;
    background: #dae9f5;
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
    }
`;
