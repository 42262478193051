import styled from 'styled-components';
import { Typography } from '../../../../ui-kit';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 530px;

    @media (max-width: 1024px) {
        width: auto;
        align-items: center;
        text-align: center;
    }
`;

export const ContactsContainer = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;

    div:last-child {
        margin-top: 24px;
    }
`;

export const ContactBlock = styled.div`
    display: flex;
    align-items: center;
`;

export const Contact = styled(Typography)`
    margin-left: 10px;
    font-weight: 400;
`;
