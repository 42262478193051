const GitLabLogo = (props) => {
    return (
        <svg {...props} width='148' height='32' viewBox='0 0 148 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_781_907)'>
                <path
                    d='M59.7525 12.0555H64.6122C63.8016 6.88854 59.328 3.28961 53.5061 3.28961C46.6176 3.28961 41.4485 8.35415 41.4485 16.8363C41.4485 25.1669 46.385 30.3339 53.6469 30.3339C60.1642 30.3339 64.8298 26.1419 64.8298 19.3557V16.1941H54.1482V19.9211H60.2154C60.1386 23.6757 57.632 26.0523 53.6725 26.0523C49.265 26.0523 46.2442 22.7499 46.2442 16.7851C46.2442 10.8587 49.3162 7.56908 53.5701 7.56908C56.7445 7.56908 58.9162 9.26508 59.7525 12.0555Z'
                    fill='#171321'
                />
                <path
                    d='M68.0427 29.9733H72.6955V10.24H68.0427V29.9733ZM70.3893 7.10401C71.8677 7.10401 73.0752 5.97121 73.0752 4.58454C73.0752 3.19788 71.8677 2.05228 70.3893 2.05228C68.9109 2.05228 67.6907 3.18294 67.6907 4.58454C67.6907 5.98614 68.8896 7.10401 70.3808 7.10401H70.3893Z'
                    fill='#171321'
                />
                <path
                    d='M86.6284 10.24H82.7329V5.49973H78.0801V10.24H75.2791V13.8389H78.0801V24.8171C78.0545 28.5312 80.7553 30.3637 84.2497 30.2549C85.1806 30.2364 86.1037 30.0804 86.9889 29.792L86.2038 26.1525C85.7592 26.2679 85.3021 26.3288 84.8428 26.3339C83.6716 26.3339 82.7329 25.9221 82.7329 24.0448V13.8283H86.6284V10.24Z'
                    fill='#171321'
                />
                <path d='M90.2295 29.9733H106.592V25.9755H94.9996V3.64801H90.2295V29.9733Z' fill='#171321' />
                <path
                    d='M115.505 30.3723C118.603 30.3723 120.452 28.9195 121.301 27.2619H121.455V29.9733H125.935V16.7595C125.935 11.5413 121.681 9.97333 117.914 9.97333C113.762 9.97333 110.575 11.8229 109.547 15.4219L113.892 16.0405C114.353 14.6901 115.665 13.5339 117.946 13.5339C120.107 13.5339 121.289 14.6389 121.289 16.5803V16.6571C121.289 17.9925 119.887 18.0587 116.403 18.4299C112.574 18.8416 108.911 19.9851 108.911 24.4331C108.898 28.3157 111.738 30.3723 115.505 30.3723ZM117.035 26.9589C115.093 26.9589 113.705 26.0715 113.705 24.3627C113.705 22.5749 115.26 21.8304 117.331 21.5339C118.554 21.3675 120.996 21.0581 121.598 20.5717V22.8971C121.611 25.088 119.836 26.9525 117.035 26.9525V26.9589Z'
                    fill='#171321'
                />
                <path
                    d='M129.361 29.9733H133.937V26.8629H134.206C134.94 28.3029 136.469 30.3211 139.863 30.3211C144.516 30.3211 148 26.6304 148 20.128C148 13.5467 144.414 9.97334 139.851 9.97334C136.367 9.97334 134.914 12.0683 134.206 13.4955H134.01V3.64801H129.361V29.9733ZM133.924 20.1024C133.924 16.2624 135.569 13.7899 138.564 13.7899C141.662 13.7899 143.258 16.4245 143.258 20.1024C143.258 23.7803 141.638 26.5024 138.564 26.5024C135.595 26.5024 133.924 23.9317 133.924 20.1024Z'
                    fill='#171321'
                />
                <path
                    d='M32.6763 12.6656L32.6315 12.5461L28.1089 0.748808C28.0173 0.517164 27.8544 0.320659 27.6438 0.18774C27.4866 0.0871252 27.3081 0.0247317 27.1224 0.00555888C26.9368 -0.0136139 26.7493 0.0109682 26.5748 0.0773346C26.4004 0.143701 26.244 0.250006 26.118 0.387728C25.9921 0.525451 25.9002 0.690761 25.8497 0.870408L22.7969 10.2165H10.4342L7.38139 0.870408C7.33047 0.691029 7.23834 0.526031 7.11234 0.388572C6.98635 0.251113 6.82997 0.144996 6.6557 0.0786839C6.48142 0.0123717 6.29406 -0.0123013 6.10856 0.0066331C5.92306 0.0255675 5.74455 0.0875848 5.58726 0.18774C5.37662 0.320659 5.21373 0.517164 5.12219 0.748808L0.601658 12.5483L0.554725 12.6656C-0.0960997 14.3665 -0.176279 16.2329 0.326274 17.9834C0.828827 19.7339 1.88687 21.2735 3.34086 22.3701L3.35793 22.3829L3.39633 22.4128L10.2763 27.5691L13.6897 30.1483L15.7633 31.7163C16.0066 31.9001 16.3032 31.9995 16.6081 31.9995C16.913 31.9995 17.2096 31.9001 17.4529 31.7163L19.5265 30.1483L22.9398 27.5691L29.8689 22.3829L29.8881 22.368C31.342 21.2718 32.4001 19.7326 32.9031 17.9826C33.406 16.2326 33.3264 14.3665 32.6763 12.6656Z'
                    fill='#E24329'
                />
                <path
                    d='M32.6763 12.6656L32.6315 12.5461C30.428 12.9983 28.3517 13.9323 26.5515 15.2811L16.6208 22.7904L22.944 27.5691L29.8731 22.3829L29.8923 22.368C31.3455 21.2712 32.4028 19.7318 32.9049 17.9818C33.4071 16.2318 33.3268 14.366 32.6763 12.6656Z'
                    fill='#FC6D26'
                />
                <path
                    d='M10.2764 27.5691L13.6897 30.1483L15.7633 31.7163C16.0066 31.9001 16.3032 31.9995 16.6081 31.9995C16.913 31.9995 17.2096 31.9001 17.4529 31.7163L19.5265 30.1483L22.9398 27.5691L16.6166 22.7904L10.2764 27.5691Z'
                    fill='#FCA326'
                />
                <path
                    d='M6.67953 15.2811C4.87997 13.9329 2.8044 12.9997 0.601658 12.5483L0.554725 12.6656C-0.0960997 14.3665 -0.176279 16.2329 0.326274 17.9834C0.828827 19.7339 1.88687 21.2735 3.34086 22.3701L3.35793 22.3829L3.39633 22.4128L10.2763 27.5691L16.6038 22.7904L6.67953 15.2811Z'
                    fill='#FC6D26'
                />
            </g>
            <defs>
                <clipPath id='clip0_781_907'>
                    <rect width='148' height='32' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default GitLabLogo;
