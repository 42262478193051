import styled from 'styled-components';

export const Toast = styled.div`
    border: 2px solid ${({ borderColor }) => borderColor};
    background-color: ${({ backgroundColor }) => backgroundColor};
    padding: 16px 22px;
    border-radius: 6px;
    max-width: 480px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const ButtonClose = styled.div`
    position: absolute;
    cursor: pointer;
    width: 24px;
    height: 24px;
    right: 5px;
    top: 5px;
`;
