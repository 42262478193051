import { memo } from 'react';

import * as Styled from './cards.styles';
import background1 from './background1.webp';
import background2 from './background2.webp';
import background3 from './background3.webp';
import { PATH } from '../../helpers';
import HPLogo from '../../../assets/images/hp-logo.png';
import AxiellLogo from '../../../assets/images/axiell-logo.png';
import ShiftPartnerAppointments from '../../../assets/images/shift-partner-appointments.png';

const cardsContent = [
    {
        id: 1,
        background: background1,
        description: 'Improving the development of a web book store',
        link: PATH.caseStudy1,
        companyLogo: HPLogo,
        logoWidth: 32,
        logoHeight: 32,
    },
    {
        id: 2,
        background: background2,
        description: 'Establishing AQA for an industrial line analysis platform',
        link: PATH.caseStudy2,
        companyLogo: AxiellLogo,
        logoWidth: 66,
        logoHeight: 42,
    },
    {
        id: 3,
        background: background3,
        description: 'Improving the Quality Assurance of a healthcare platform project',
        link: PATH.caseStudy3,
        companyLogo: ShiftPartnerAppointments,
        logoWidth: 200,
        logoHeight: 33,
    },
];

const Cards = ({ cardId = 0 }) => {
    return (
        <Styled.Cards>
            {cardsContent
                .filter(({ id }) => id !== cardId)
                .map(({ background, description, link, companyLogo, logoWidth, logoHeight }) => (
                    <Styled.Link to={`/${link}`} key={description}>
                        <Styled.Card>
                            <Styled.Background background={background} />
                            <Styled.DescriptionBlock>
                                <Styled.CompanyLogo
                                    width={logoWidth}
                                    height={logoHeight}
                                    src={companyLogo}
                                    alt={description}
                                />
                                <Styled.Description type='h6'>{description}</Styled.Description>
                            </Styled.DescriptionBlock>
                        </Styled.Card>
                    </Styled.Link>
                ))}
        </Styled.Cards>
    );
};

export default memo(Cards);
