const MoneyBag = (props) => {
    return (
        <svg {...props} width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_765_3356)'>
                <path
                    d='M24 41.4107C18.2925 41.4107 13.6492 36.7673 13.6492 31.0599C13.6492 25.3525 18.2925 20.7092 24 20.7092C29.7074 20.7092 34.3507 25.3525 34.3507 31.0599C34.3507 36.7673 29.7074 41.4107 24 41.4107Z'
                    fill='#BCDBF4'
                />
                <path
                    d='M25.2074 30.0839C24.4044 29.8 23.5168 29.4576 23.0217 29.0692C22.6913 28.8102 22.8292 27.854 23.5021 27.6513C24.3703 27.3895 25.0458 27.8666 25.1246 27.9256C25.5069 28.2411 26.0735 28.2051 26.412 27.8357C26.7634 27.4524 26.7384 26.8506 26.3406 26.4986C26.1276 26.3092 25.6203 25.9869 24.9374 25.8153V25.4119C24.9374 24.8941 24.5176 24.4744 23.9999 24.4744C23.4821 24.4744 23.0624 24.8941 23.0624 25.4119V25.8277C20.847 26.428 20.3096 29.325 21.8645 30.5445C22.4355 30.9923 23.2229 31.3711 24.5825 31.8516C25.4513 32.1587 25.4328 32.8607 25.3845 33.1482C25.2856 33.7361 24.7912 34.3714 23.9388 34.377C23.075 34.3826 22.8277 34.3435 22.172 33.9145C21.7387 33.6311 21.1577 33.7525 20.8742 34.1859C20.5908 34.6192 20.7123 35.2002 21.1456 35.4837C21.8727 35.9594 22.4073 36.1501 23.0624 36.2183V36.7079C23.0624 37.2257 23.4821 37.6454 23.9999 37.6454C24.5176 37.6454 24.9374 37.2257 24.9374 36.7079V36.1024C26.2235 35.7047 27.0459 34.5743 27.2334 33.4592C27.4859 31.9579 26.6717 30.6014 25.2074 30.0839V30.0839Z'
                    fill='#1E274F'
                />
                <path
                    d='M31.6767 14.9864C32.9356 14.7072 33.8801 13.5827 33.8801 12.2411V12.2258C33.8801 10.675 32.6184 9.41334 31.0676 9.41334H29.6207L31.3226 5.1585C32.3088 2.69269 30.4934 0 27.8302 0H20.1697C17.5142 0 15.6883 2.68584 16.6773 5.15841L18.3792 9.41325H16.9323C15.3815 9.41325 14.1198 10.6749 14.1198 12.2258V12.241C14.1198 13.5827 15.0643 14.7072 16.3232 14.9863C10.7509 19.1204 6.96571 27.1742 6.96571 33.7898C6.96571 38.2803 8.72437 41.9862 12.0517 44.5071C15.0678 46.7922 19.1994 48 24 48C28.8006 48 32.9321 46.7922 35.9482 44.5072C39.2754 41.9863 41.0342 38.2803 41.0342 33.7898C41.0342 27.1819 37.2569 19.1264 31.6767 14.9864V14.9864ZM18.4182 4.46213C17.9247 3.22838 18.8262 1.875 20.1697 1.875H27.8302C29.1585 1.875 30.0809 3.21422 29.5817 4.46213L27.6012 9.41334H20.3988L18.4182 4.46213ZM15.9949 12.241V12.2258C15.9949 11.7088 16.4153 11.2882 16.9324 11.2882H31.0675C31.5845 11.2882 32.005 11.7088 32.005 12.2258V12.241C32.005 12.758 31.5845 13.1785 31.0675 13.1785C28.6708 13.1785 21.8191 13.1785 19.6498 13.1785H16.9324C16.4153 13.1785 15.9949 12.758 15.9949 12.241V12.241ZM24 46.125C16.6716 46.125 8.84081 42.8845 8.84081 33.7898C8.84081 27.1177 13.2565 18.0529 19.849 15.0535H28.1508C34.7434 18.0529 39.1592 27.1178 39.1592 33.7898C39.1592 42.8845 31.3284 46.125 24 46.125V46.125Z'
                    fill='#1E274F'
                />
            </g>
            <defs>
                <clipPath id='clip0_765_3356'>
                    <rect width='48' height='48' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default MoneyBag;
