import { Route, Routes } from 'react-router-dom';

import { MainIntroduction } from './components/main-introduction';
import { HowHelps } from './components/how-helps';
import { BookCallForm } from './components/book-call-form';
import { CaseStudies, ScrollToTop } from './../../shared/components';
import { AQAServicesForm } from './../../shared/components';
import { ICON_TYPES } from '../../shared/ui-kit';
import { Packages } from './components/packages';

const Main = () => {
    const whyUsCardsContent = [
        {
            icon: ICON_TYPES.network,
            title: 'Test every component',
            description:
                'Automated tests may be run both on the back-end and front-end, ensuring the detection of issues on both sides.',
        },
        {
            icon: ICON_TYPES.timer,
            title: 'Spend less time on testing',
            description:
                'It takes time and lots of creative efforts for manual QA engineers to perform the exploratory testing sessions, analyze the results and build working test cases. A thorough manual regression test suite, for example, can take up to a week.',
        },
        {
            icon: ICON_TYPES.moneyBag,
            title: 'Save money',
            description:
                'Automated tests are much faster than humans, allowing development teams to run tests for unique configurations (browsers, resolutions, devices) in parallel or switch between them.',
        },
        {
            icon: ICON_TYPES.error,
            title: 'Detect issues earlier in the process',
            description:
                'Automation tests are integrated into your CI/CD pipeline. Following a shift-left testing approach, an automation test suite may be triggered to run connected to a part of a changed application. It helps to find existing issues immediately in the earliest delivery cycle and reduces the cost of a bug.',
        },
        {
            icon: ICON_TYPES.solved,
            title: 'Fix issues easier',
            description:
                'Automated reporting tools produce videos, screenshots, and reports for each test run. This information helps the developers to detect bottlenecks and find the root cause of issues more effectively.',
        },
        {
            icon: ICON_TYPES.user,
            title: 'Improve user experience',
            description:
                'Automated testing may also be scheduled to run on a production environment, which allows finding issues faster than end-users, resulting in a better user experience and reducing the volume of customer support tickets.',
        },
    ];

    return (
        <Routes>
            <Route
                path='/'
                element={
                    <>
                        <ScrollToTop />
                        <MainIntroduction />
                        <HowHelps cardsContent={whyUsCardsContent} />
                        <BookCallForm />
                        <Packages />
                        <CaseStudies title='Case studies' />
                        <AQAServicesForm />
                    </>
                }
            />
        </Routes>
    );
};

export default Main;
