import React, { useState, createContext } from 'react';
import { createPortal } from 'react-dom';

import { Toast } from '../ui-kit/toast';

export const ToastContext = createContext(null);

// Create a random ID
function generateUEID() {
    let first = (Math.random() * 46656) | 0;
    let second = (Math.random() * 46656) | 0;
    first = ('000' + first.toString(36)).slice(-3);
    second = ('000' + second.toString(36)).slice(-3);

    return first + second;
}

const styles = {
    position: 'fixed',
    top: '20px',
    right: '20px',
    zIndex: 1000,
};

const ToastProvider = (props) => {
    const [toasts, setToasts] = useState([]);
    const open = ({ content, type }) =>
        setToasts((currentToasts) => [...currentToasts, { id: generateUEID(), content, type }]);
    const close = (id) => setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id));
    const contextValue = { open };

    return (
        <ToastContext.Provider value={contextValue}>
            {props.children}
            {createPortal(
                <div style={styles}>
                    {toasts.map(({ id, content, type }) => {
                        debugger;
                        const onClose = () => close(id);
                        return (
                            <Toast key={id} type={type} close={onClose}>
                                {content}
                            </Toast>
                        );
                    })}
                </div>,
                document.body
            )}
        </ToastContext.Provider>
    );
};

export default ToastProvider;
