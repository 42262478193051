import { Route, Routes } from 'react-router-dom';

import background from './background.webp';
import { FirstSection } from '../../shared/components/first-section';
import { AQAServicesForm, CaseStudies, ScrollToTop, SecondSection } from '../../shared/components';
import { ICON_TYPES } from '../../shared/ui-kit';
import { Helmet } from 'react-helmet';

const content = [
    {
        title: 'Project scope',
        description:
            'An IoT web-based platform that collects data from built-in sensors (energy consumption, level of noise, and vibration) from industrial lines. Based on the collected data, the platform allows end-users to analyze the current situation and get ML predictions for future energy consumption and equipment repair cycle.',
        icon: '',
    },
    {
        title: 'The challenge',
        description:
            'The IoT platform had 200+ charts for each sensor and module that are dynamically changing in real time. The platform also included a special gate to transform data from the equipment to JSON.',
        additionalDescription:
            "The Customer's Manual QA engineers cound not ensure that every 200 charts receive the correct data from each sensor. Testing production environment was also limited, as the industrial line was actively used 24/7 and a stop would cause significant losses.",
        icon: '',
    },
    {
        title: 'The solution',
        description:
            'The automated quality assurance engineers built test scenarios to validate the availability of 200+ charts, the correctness of data rendering, and the accuracy of data delivery from the back-end routes (API) to the right chart for each sensor.',
        icon: '',
    },
    { title: 'Technologies', description: '', icon: [ICON_TYPES.cypressLogo, ICON_TYPES.gitLabLogo] },
    {
        title: 'The results',
        description: [
            'Implemented Cypress UI and API test for back-end and integrated to the CI pipeline.',
            'The quality assurance team acquired the ability to perform all automation tests for each sensor and line and be sure that data is correctly rendered.',
        ],
        icon: '',
    },
];

const CaseStudy2 = () => {
    return (
        <Routes>
            <Route
                path='/'
                element={
                    <>
                        <Helmet>
                            <title>Establishing AQA for an IoT industrial line analysis platform</title>
                            <meta
                                itemprop='name'
                                content='Establishing AQA for an IoT industrial line analysis platform'
                            />
                            <meta
                                name='og:title'
                                content='Establishing AQA for an IoT industrial line analysis platform'
                            />
                            <meta
                                name='twitter:title'
                                content='Establishing AQA for an IoT industrial line analysis platform'
                            />
                            <meta
                                name='description'
                                content='Building the automated test scenarios to validate the availability, rendering, and accuracy of the visualization of the data that is received by 200+ charts from each sensor on the industrial line.'
                            />
                            <meta
                                itemprop='description'
                                content='Building the automated test scenarios to validate the availability, rendering, and accuracy of the visualization of the data that is received by 200+ charts from each sensor on the industrial line.'
                            />
                            <meta
                                name='og:description'
                                content='Building the automated test scenarios to validate the availability, rendering, and accuracy of the visualization of the data that is received by 200+ charts from each sensor on the industrial line.'
                            />
                            <meta
                                name='twitter:description'
                                content='Building the automated test scenarios to validate the availability, rendering, and accuracy of the visualization of the data that is received by 200+ charts from each sensor on the industrial line.'
                            />
                        </Helmet>
                        <ScrollToTop />
                        <FirstSection
                            background={background}
                            title='Establishing AQA for an IoT industrial line analysis platform'
                            description='Building the automated test scenarios to validate the availability, rendering, and accuracy of the visualization of the data that is received by 200+ charts from each sensor on the industrial line'
                            titleWidth={760}
                        />
                        <SecondSection content={content} />
                        <AQAServicesForm />
                        <CaseStudies title='Related cases' cardId={2} />
                    </>
                }
            />
        </Routes>
    );
};

export default CaseStudy2;
