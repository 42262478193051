import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';

import { Footer, Header } from './shared/components';
import { PATH } from './shared/helpers';
import { Main } from './pages/main';
import { CaseStudy1 } from './pages/case-study-1';
import { CaseStudy2 } from './pages/case-study-2';
import { CaseStudy3 } from './pages/case-study-3';
import { Landing } from './pages/main/Landing';
import { ThankYouPage } from './pages/thank-you';

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID || 'GTM-WTC9QCB',
});

const App = () => {
    const location = useLocation();

    useEffect(() => {
        const page = location.pathname;
        ReactGA.set({ page });
        ReactGA.pageview(page);
    }, [location]);

    return (
        <>
            <Header />
            <Routes>
                <Route path={`${PATH.main}/thank-you`} element={<ThankYouPage />} />
                <Route path={`${PATH.main}/landing`} element={<Landing />} />
                <Route path={`${PATH.main}`} element={<Main />} />
                <Route path={`/${PATH.caseStudy1}`} element={<CaseStudy1 />} />
                <Route path={`/${PATH.caseStudy2}`} element={<CaseStudy2 />} />
                <Route path={`/${PATH.caseStudy3}`} element={<CaseStudy3 />} />
                <Route path='*' element={<Navigate to={PATH.main} />} />
            </Routes>
            <Footer />
        </>
    );
};

export default App;
