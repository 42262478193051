import * as Styled from './OurClientsSay.styles';
import TestimonialAuthorImage from '../../../assets/images/testimonial-author.png';

const OurClientsSay = () => {
    return (
        <Styled.Container>
            <Styled.Title type='h3'>Our clients say...</Styled.Title>
            <Styled.Testimonial>
                <Styled.TestimonialText>
                    Codex provided us a very skilled managed team of AQA developers. Their expertise helped us reduce
                    the involvement of manual QA engineers by 5 times, while retaining the high quality of each release.
                    The communication was also smooth and efficient. We are absolutely satisfied with the results.
                </Styled.TestimonialText>
                <Styled.TestimonialAuthor>
                    <Styled.Avatar src={TestimonialAuthorImage} alt='Testimonial author' />
                    <Styled.AuthorName>Dan Norton, Quality assurance manager, HP</Styled.AuthorName>
                </Styled.TestimonialAuthor>
            </Styled.Testimonial>
        </Styled.Container>
    );
};

export default OurClientsSay;
