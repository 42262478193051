import styled from 'styled-components';
import { Typography } from '../../../../../../shared/ui-kit';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 608px;

    @media (max-width: 1024px) {
        width: auto;
    }
`;

export const Title = styled(Typography)`
    font-weight: bold;
    @media (max-width: 1024px) {
        text-align: center;
    }
`;

export const Description = styled(Typography)`
    margin-top: 24px;
    font-weight: 400;

    @media (max-width: 1024px) {
        text-align: center;
    }
`;
