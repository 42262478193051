import { useContext } from 'react';

import * as Styled from './book-call-form.styles';
import { LeftContent } from './components/left-content';
import { RightContent } from './components/right-content';
import { NavigateContext } from '../../../../shared/helpers';

const BookCallForm = () => {
    const { bookCallRef } = useContext(NavigateContext);

    return (
        <Styled.BookCallContainer ref={bookCallRef}>
            <Styled.BookCallContent>
                <LeftContent />
                <RightContent />
            </Styled.BookCallContent>
        </Styled.BookCallContainer>
    );
};

export default BookCallForm;
