import { ICON_TYPES } from './icon.types';
import MoneyBag from './MoneyBag';
import Network from './Network';
import User from './User';
import Timer from './Timer';
import Solved from './Solved';
import Error from './Error';
import CypressLogo from './CypressLogo';
import GitLabLogo from './GitLabLogo';
import LinkedInLogo from './linkedInLogo';
import Codex from './Codex';
import Logo from './Logo';
import EmailIcon from './EmailIcon';
import WhitePhone from './WhitePhone';
import BluePhone from './BluePhone';
import Mail from './Mail';
import YahooMail from './YahooMail';
import ArrowNext from './ArrowNext';
import ArrowBack from './ArrowBack';
import Close from './Close';
import Messages from './Messages';
import Support from './Support.jsx';
import Directions from './Directions';
import Balance from './Balance';
import { ReactComponent as DownloadIcon } from '../../../assets/images/download-icon.svg';

const MAP = {
    [ICON_TYPES.moneyBag]: (props) => <MoneyBag {...props} />,
    [ICON_TYPES.network]: (props) => <Network {...props} />,
    [ICON_TYPES.user]: (props) => <User {...props} />,
    [ICON_TYPES.timer]: (props) => <Timer {...props} />,
    [ICON_TYPES.solved]: (props) => <Solved {...props} />,
    [ICON_TYPES.error]: (props) => <Error {...props} />,
    [ICON_TYPES.cypressLogo]: (props) => <CypressLogo {...props} />,
    [ICON_TYPES.gitLabLogo]: (props) => <GitLabLogo {...props} />,
    [ICON_TYPES.linkedInLogo]: (props) => <LinkedInLogo {...props} />,
    [ICON_TYPES.codex]: (props) => <Codex {...props} />,
    [ICON_TYPES.emailIcon]: (props) => <EmailIcon {...props} />,
    [ICON_TYPES.logo]: (props) => <Logo {...props} />,
    [ICON_TYPES.whitePhone]: (props) => <WhitePhone {...props} />,
    [ICON_TYPES.bluePhone]: (props) => <BluePhone {...props} />,
    [ICON_TYPES.mail]: (props) => <Mail {...props} />,
    [ICON_TYPES.yahooMail]: (props) => <YahooMail {...props} />,
    [ICON_TYPES.arrowNext]: (props) => <ArrowNext {...props} />,
    [ICON_TYPES.arrowBack]: (props) => <ArrowBack {...props} />,
    [ICON_TYPES.close]: (props) => <Close {...props} />,
    [ICON_TYPES.messages]: (props) => <Messages {...props} />,
    [ICON_TYPES.balance]: (props) => <Balance {...props} />,
    [ICON_TYPES.directions]: (props) => <Directions {...props} />,
    [ICON_TYPES.support]: (props) => <Support {...props} />,
    [ICON_TYPES.download]: (props) => <DownloadIcon />,
};

const Icon = (props) => {
    const { type } = props;
    return MAP[type](props);
};

export default Icon;
