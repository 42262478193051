import styled from 'styled-components';
import { Button, Typography } from '../../../shared/ui-kit';
import { Link as lnk } from 'react-router-dom';

export const Container = styled.div`
    padding: 32px 0 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Image = styled.img`
    max-width: 440px;
    max-height: 399px;
    width: 100%;
    height: 100%;
`;

export const Text = styled(Typography)`
    text-align: center;
    margin: 12px 20px 32px;
    max-width: 530px;
`;

export const DownloadButton = styled(Button)`
    width: inherit;
    max-width: inherit;
`;

export const DownloadButtonText = styled(Typography)`
    color: #fff;
    margin-left: 8px;
`;

export const Link = styled(lnk)`
    display: block;
    text-decoration: none;
    width: 100%;
    max-width: 418px;
    @media screen and (max-width: 480px) {
        max-width: 350px;
    }
`;
