const ArrowNext = (props) => {
    return (
        <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M9.58525 18.723L15.7972 12.5171C15.871 12.4433 15.9231 12.3632 15.9535 12.277C15.9845 12.1909 16 12.0985 16 12C16 11.9015 15.9845 11.8091 15.9535 11.723C15.9231 11.6368 15.871 11.5567 15.7972 11.4828L9.58525 5.25858C9.41321 5.08619 9.19816 5 8.94009 5C8.68203 5 8.46083 5.09235 8.2765 5.27704C8.09217 5.46174 8 5.67722 8 5.92348C8 6.16974 8.09217 6.38522 8.2765 6.56992L13.6959 12L8.2765 17.4301C8.10445 17.6025 8.01843 17.8147 8.01843 18.0669C8.01843 18.3196 8.1106 18.5383 8.29493 18.723C8.47926 18.9077 8.69432 19 8.94009 19C9.18587 19 9.40092 18.9077 9.58525 18.723Z'
                fill='#64A4D5'
            />
        </svg>
    );
};

export default ArrowNext;
