import * as Styled from './toast.styles';
import useTimeout from './useTimeout.hook';
import { Icon, ICON_TYPES } from '../icon';
import { Typography } from '../typography';

const getBackgroundColorByType = (type) => {
    switch (type) {
        case 'Success':
            return '#69f0ae';
        case 'Warning':
            return '#eeff41';
        case 'Error':
            return '#ff6e40';
        default:
            throw new Error('unknown type');
    }
};

const getBorderColorByType = (type) => {
    switch (type) {
        case 'Success':
            return '#00e676';
        case 'Warning':
            return '#c6ff00';
        case 'Error':
            return '#ff3d00';
        default:
            throw new Error('unknown type');
    }
};

const Toast = ({ children, close, type = 'Success' }) => {
    useTimeout(close, 3000);

    const backgroundColor = getBackgroundColorByType(type);
    const borderColor = getBorderColorByType(type);

    return (
        <Styled.Toast backgroundColor={backgroundColor} borderColor={borderColor}>
            <Typography type='h6'>{type}!</Typography>
            <Typography>{children}</Typography>
            <Styled.ButtonClose onClick={close}>
                <Icon type={ICON_TYPES.close} />
            </Styled.ButtonClose>
        </Styled.Toast>
    );
};

export default Toast;
