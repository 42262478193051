const BalanceIcon = (props) => {
    return (
        <svg {...props} width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_1112_856)'>
                <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M6.79686 13.2188C7.16087 13.2188 7.49198 13.4295 7.64614 13.7592L12.5809 24.3149C12.8001 24.784 12.5977 25.3419 12.1286 25.5612C11.6596 25.7805 11.1016 25.578 10.8823 25.109L6.79685 16.3699L2.71132 25.109C2.49204 25.578 1.93405 25.7805 1.465 25.5612C0.99596 25.3419 0.793486 24.7839 1.01277 24.3149L5.94758 13.7592C6.10174 13.4295 6.43285 13.2187 6.79686 13.2188Z'
                    fill='#1E274F'
                />
                <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M15.5625 7.12491C15.5625 5.5762 16.826 4.3125 18.375 4.3125C19.0942 4.3125 19.7518 4.58486 20.25 5.0317C20.7482 4.5849 21.4059 4.31259 22.125 4.31259C22.8442 4.31259 23.5018 4.58491 24 5.03172C24.4982 4.58491 25.1558 4.31259 25.875 4.31259C26.8683 4.31259 27.7442 4.8321 28.2448 5.61333C28.653 5.38213 29.1241 5.25 29.625 5.25C31.174 5.25 32.4375 6.51333 32.4375 8.06241V8.99991H41.2032C41.721 8.99991 42.1407 9.41964 42.1407 9.93741V14.1562C42.1407 14.6739 41.721 15.0937 41.2032 15.0937H32.4375V42.75C32.4375 43.2678 32.0178 43.6875 31.5 43.6875C30.9822 43.6875 30.5625 43.2678 30.5625 42.75V8.06241C30.5625 7.54899 30.1385 7.12501 29.625 7.12501C29.1115 7.12501 28.6875 7.54896 28.6875 8.0625V8.38416C28.6875 8.90193 28.2678 9.32167 27.75 9.32167C27.2322 9.32167 26.8125 8.90193 26.8125 8.38416V7.1251C26.8125 6.61155 26.3885 6.1876 25.875 6.1876C25.3615 6.1876 24.9375 6.61159 24.9375 7.125V8.38407C24.9375 8.90184 24.5178 9.32157 24 9.32157C23.4822 9.32157 23.0625 8.90184 23.0625 8.38407V7.125C23.0625 6.61159 22.6385 6.1876 22.125 6.1876C21.6115 6.1876 21.1876 6.6115 21.1875 7.125C21.1875 7.12504 21.1875 7.12497 21.1875 7.125V8.38407C21.1875 8.90184 20.7678 9.32157 20.25 9.32157C19.7322 9.32157 19.3125 8.90184 19.3125 8.38407V7.1251C19.3125 7.12507 19.3125 7.12513 19.3125 7.1251C19.3125 6.61162 18.8885 6.18751 18.375 6.18751C17.8616 6.18751 17.4375 6.61168 17.4375 7.12491V14.1562C17.4375 14.6739 17.0178 15.0937 16.5 15.0937H15.6201L17.3429 18.6327C17.4052 18.7606 17.4375 18.9009 17.4375 19.0431V42.75C17.4375 43.2678 17.0178 43.6875 16.5 43.6875C15.9822 43.6875 15.5625 43.2678 15.5625 42.75V19.2591L13.5348 15.0937H6.79688C6.27911 15.0937 5.85938 14.6739 5.85938 14.1562V9.93741C5.85938 9.41964 6.27911 8.99991 6.79688 8.99991H15.5625V7.12491ZM15.5625 10.8749H7.73438V13.2187H15.5625V10.8749ZM32.4375 13.2187H40.2657V10.8749H32.4375V13.2187Z'
                    fill='#1E274F'
                />
                <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M16.0312 21.5869C16.0312 21.0691 16.451 20.6494 16.9688 20.6494H31.0313C31.549 20.6494 31.9688 21.0691 31.9688 21.5869C31.9688 22.1047 31.549 22.5244 31.0313 22.5244H16.9688C16.451 22.5244 16.0312 22.1047 16.0312 21.5869Z'
                    fill='#1E274F'
                />
                <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M16.0312 28.1494C16.0312 27.6316 16.451 27.2119 16.9688 27.2119H31.0313C31.549 27.2119 31.9688 27.6316 31.9688 28.1494C31.9688 28.6672 31.549 29.0869 31.0313 29.0869H16.9688C16.451 29.0869 16.0312 28.6672 16.0312 28.1494Z'
                    fill='#1E274F'
                />
                <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M19.7805 24.8682C19.7805 24.3504 20.2003 23.9307 20.718 23.9307H20.7188C21.2365 23.9307 21.6563 24.3504 21.6563 24.8682C21.6563 25.3859 21.2365 25.8057 20.7188 25.8057H20.718C20.2003 25.8057 19.7805 25.3859 19.7805 24.8682Z'
                    fill='#1E274F'
                />
                <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M41.2031 13.2188C41.5671 13.2188 41.8982 13.4295 42.0524 13.7592L46.9871 24.3149C47.2064 24.784 47.0039 25.3419 46.5349 25.5612C46.0658 25.7805 45.5078 25.578 45.2885 25.109L41.2031 16.3699L37.1176 25.109C36.8983 25.578 36.3403 25.7805 35.8713 25.5612C35.4022 25.3419 35.1997 24.7839 35.419 24.3149L40.3538 13.7592C40.508 13.4295 40.8391 13.2187 41.2031 13.2188Z'
                    fill='#1E274F'
                />
                <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M0 23.9307H13.5938V24.8682C13.5938 28.6216 10.5503 31.665 6.79688 31.665C3.04341 31.665 0 28.6216 0 24.8682V23.9307ZM34.4063 23.9307H48.0001V24.8682C48.0001 28.6216 44.9567 31.665 41.2032 31.665C37.4497 31.665 34.4063 28.6216 34.4063 24.8682V23.9307Z'
                    fill='#BCDBF4'
                />
            </g>
            <defs>
                <clipPath id='clip0_1112_856'>
                    <rect width='48' height='48' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default BalanceIcon;
