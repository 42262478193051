import * as Styled from './left-content.styles';

const LeftContent = () => {
    return (
        <Styled.Container>
            <Styled.Title type='h3'>Automated testing might benefit your project?</Styled.Title>
            <Styled.Title type='h3'>Need an AQA team?</Styled.Title>
            <Styled.Description type='h6'>Request a 30-min call with our experts and find out!</Styled.Description>
        </Styled.Container>
    );
};

export default LeftContent;
