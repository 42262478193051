import TeamIcon from '../../../../assets/images/team-icon.svg';
import ClockIcon from '../../../../assets/images/clock-icon.svg';
import SupportIcon from '../../../../assets/images/support-icon.svg';
import CheckDoneIcon from '../../../../assets/images/check-done-icon.svg';
import MinusIcon from '../../../../assets/images/minus-icon.svg';
import BrowserIcon from '../../../../assets/images/browser-icon.svg';
import BrowserCodeIcon from '../../../../assets/images/browser-coding-icon.svg';

export const packages = [
    {
        icon: SupportIcon,
        title: 'Manual QA',
        description:
            'Establish your Quality Assurance process with test execution based on functional and non-functional requirements, in addition to back-end testing (API level) for web-based desktop and mobile software.',
        accordion: [
            {
                title: 'Deliverables',
                pros: {
                    icon: CheckDoneIcon,
                    items: [
                        'Creation of a test plan (defining the scope of testing, tools, schedule, testing levels, etc.)',
                        'Creation and maintenance of test cases and checklists',
                        'Provision of Quality Assurance reports with product and process quality metrics at a defined frequency',
                        'Bug reporting in bug tracking systems',
                    ],
                },
            },
            {
                title: 'Services Included',
                pros: {
                    icon: CheckDoneIcon,
                    items: [
                        'Requirements tests',
                        'Functional tests',
                        'Non-functional UI tests (the scope of non-functional tests to be defined)',
                        'Non-functional cross-browser and multiplatform tests',
                        'Non-functional localization and Internationalization tests (l10n & l18n)',
                        'API back-end testing (Postman collection with JS-based assertions)',
                    ],
                },
                team: {
                    icon: TeamIcon,
                    title: '1-2 Manual QA engineers (depending on project size)',
                },
                duration: {
                    icon: ClockIcon,
                    title: '3 months',
                },
            },
            {
                title: 'Additional Services',
                pros: {
                    icon: CheckDoneIcon,
                    items: ['Performance testing', 'Load testing', 'Stress testing'],
                },
            },
            {
                title: 'Not Included',
                pros: {
                    icon: MinusIcon,
                    items: ['Performance testing'],
                },
            },
        ],
    },
    {
        icon: BrowserIcon,
        title: 'Manual QA & Automated QA',
        description:
            'Receive all the advantages of manual testing + additional web tests with JavaScript and Cypress framework for the defined level (smoke, e2e, critical or regression), along with CI/CD integration.',
        accordion: [
            {
                title: 'Deliverables',
                pros: {
                    icon: CheckDoneIcon,
                    items: [
                        'All the services from Package 1',
                        'Defining the scope for smoke tests automation',
                        'Defining the scope for e2e tests (End-to-End)',
                        'Defining the scope for regression automated tests',
                        'Reporting test automation results with Cypress Test Runner',
                        'Creation of documentation for automated tests',
                    ],
                },
            },
            {
                title: 'Services Included',
                pros: {
                    icon: CheckDoneIcon,
                    items: [
                        'The services from package 1',
                        'API testing with Postman or integrated with the Cypress automation scope',
                        'UI and functional automated tests with Page Object pattern',
                        'Automated cross-browser and mobile view tests',
                        'Automated test cases integration with the CI/CD pipeline',
                        'Maintenance of the automated test cases',
                        'Setup of the test execution schedule and triggers',
                    ],
                },
                team: {
                    icon: TeamIcon,
                    title: '1 Manual QA and 1 Automated QA engineers',
                },
                duration: {
                    icon: ClockIcon,
                    title: '1,5-2 months',
                },
            },
            {
                title: 'Additional Services',
                pros: {
                    icon: CheckDoneIcon,
                    items: ['Performance testing', 'Load testing', 'Stress testing'],
                },
            },
            {
                title: 'Not Included',
                pros: {
                    icon: MinusIcon,
                    items: [
                        'Native/hybrid mobile applications',
                        'Unit tests',
                        'Paid tools subscription',
                        'Paid Cypress dashboard',
                    ],
                },
            },
        ],
    },
    {
        icon: BrowserCodeIcon,
        title: '100% Automated QA',
        description:
            "Get the end-to-end test automation, including smoke, e2e, and regression scopes for testing, integration with existing CI/CD pipeline, and Cypress integrations with the Customer's tools (JIRA, testRail, Slack or any supported tools).",
        accordion: [
            {
                title: 'Deliverables',
                pros: {
                    icon: CheckDoneIcon,
                    items: [
                        'All the services from package 1 (on demand)',
                        'Defining the automated testing scope for smoke, regression, e2e and extended tests',
                        'Bug reporting in bug tracking systems',
                        'Creation of documentation for automated tests (including manuals on running, maintaining, and executing automated tests)',
                        'Reporting test automation results with Cypress Test Runner or any custom dashboard',
                        "Conducting workshops for the Customer's QA engineers (on demand)",
                    ],
                },
            },
            {
                title: 'Services Included',
                pros: {
                    icon: CheckDoneIcon,
                    items: [
                        'The services from package 1 (on demand)',
                        'UI and functional automated tests with Page Object pattern or Cucumber',
                        'Setup of the parallel execution of tests to speed up the process',
                        'Automated cross-browser and mobile view tests',
                        'Automated test cases integration with the CI/CD pipeline',
                        'Maintenance of the automated test cases',
                        'Setup of the test execution schedule and triggers',
                    ],
                },
                team: {
                    icon: TeamIcon,
                    title: '2 Automated QA engineers',
                },
                duration: {
                    icon: ClockIcon,
                    title: '4 months',
                },
            },
            {
                title: 'Additional Services',
                pros: {
                    icon: CheckDoneIcon,
                    items: ['Performance testing', 'Load testing', 'Stress testing'],
                },
            },
            {
                title: 'Not Included',
                pros: {
                    icon: MinusIcon,
                    items: [
                        'Native/hybrid mobile applications',
                        'Unit tests',
                        'Paid tools subscription',
                        'Paid Cypress dashboard',
                    ],
                },
            },
        ],
    },
];
