const Codex = (props) => {
    return (
        <svg {...props} width='136' height='44' viewBox='0 0 136 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_763_2783)'>
                <path
                    d='M33.2727 14.9514C33.114 14.6844 32.7437 14.631 32.4792 14.7912L28.9351 17.0874C29.6756 18.2621 30.0988 19.5971 30.0988 21.0922C30.0988 25.1505 26.8191 28.4611 22.7989 28.4611C18.7787 28.4611 15.499 25.1505 15.499 21.0922C15.499 17.034 18.7787 13.7233 22.7989 13.7233C23.7511 13.7233 24.6503 13.8835 25.4967 14.2573L22.7989 21.1456L26.7662 14.9514L29.0408 11.4272C29.1995 11.1602 29.1466 10.7864 28.8822 10.6262C28.3532 10.3058 27.8242 9.98541 27.2952 9.71842C21.4765 6.78153 14.2824 7.84949 9.57449 12.6553C3.54415 18.5825 3.54415 28.4077 9.52159 34.4417C15.499 40.4757 25.2322 40.4757 31.2626 34.4417C36.4994 29.1553 37.1871 20.9854 33.2727 14.9514Z'
                    fill='#64A4D5'
                />
                <path
                    d='M43.6935 0C40.7842 0.640777 37.769 2.24272 35.2299 4.80582C34.5951 5.4466 34.0133 6.08738 33.5372 6.78155L31.051 6.46116L29.7814 7.74272C30.9452 8.2767 32.1089 9.07767 33.1669 10.0922L32.6379 10.6262L33.2198 11.2136C34.8067 9.61165 35.6002 8.81068 36.9755 7.42233L34.1719 11.2136C34.9654 12.1214 35.6002 13.1359 36.0234 14.0971L37.2929 12.8155L36.9755 10.3058C37.6632 9.77184 38.298 9.18447 38.9327 8.59709C41.4189 5.98058 43.0588 2.93689 43.6935 0Z'
                    fill='#64A4D5'
                />
                <path
                    d='M58.7694 16.3398H53.0035C42.5298 16.3398 42.5298 30.4369 53.0035 30.4369H58.7694C59.457 30.4369 59.986 29.903 59.986 29.2088V29.1554C59.986 28.4612 59.457 27.9272 58.7694 27.9272H53.0035C46.55 27.6602 46.55 19.1699 53.0035 18.9563H58.7694C59.457 18.9563 59.986 18.4224 59.986 17.7282V17.6214C59.986 16.9272 59.457 16.3398 58.7694 16.3398Z'
                    fill='#64A4D5'
                />
                <path
                    d='M72.9459 27.9272C72.893 27.9272 72.893 27.9272 72.9459 27.9272H72.4169H71.888C65.4344 27.6602 65.4344 19.1699 71.888 18.9563H72.4169H72.9459C79.3465 19.1699 79.3465 27.6602 72.9459 27.9272ZM72.9459 16.3398H72.4169H71.888C61.4142 16.3398 61.4142 30.4369 71.888 30.4369H72.4169H72.9459C83.4197 30.4369 83.4197 16.3398 72.9459 16.3398Z'
                    fill='#64A4D5'
                />
                <path
                    d='M135.683 28.4078L131.239 23.3883L135.63 18.4757C135.947 18.1019 136.053 17.5679 135.841 17.1408C135.63 16.7136 135.207 16.3932 134.73 16.3932C134.149 16.3932 133.62 16.6602 133.249 17.034L129.441 21.3592L125.632 17.034C125.262 16.6068 124.733 16.3932 124.151 16.3932C123.675 16.3932 123.252 16.6602 123.04 17.1408C122.828 17.5679 122.934 18.1019 123.252 18.4757L127.642 23.3883L123.199 28.4078C122.881 28.7815 122.776 29.3155 122.987 29.7427C123.199 30.1699 123.622 30.4903 124.098 30.4903C124.627 30.4903 125.103 30.2767 125.42 29.9029L129.388 25.4175L133.355 29.9029C133.672 30.2767 134.201 30.4903 134.678 30.4903C135.154 30.4903 135.577 30.2233 135.788 29.7427C136.053 29.2621 136 28.7815 135.683 28.4078Z'
                    fill='#64A4D5'
                />
                <path
                    d='M91.7775 27.9272C91.7246 27.9272 91.7246 27.9272 91.7775 27.9272H87.6515V18.903H91.7775C98.2311 19.1699 98.2311 27.6602 91.7775 27.9272ZM91.7775 16.3398H86.0117C85.324 16.3398 84.795 16.8738 84.795 17.568V29.2088C84.795 29.903 85.324 30.4369 86.0117 30.4369H91.7775C102.251 30.4369 102.251 16.3398 91.7775 16.3398Z'
                    fill='#64A4D5'
                />
                <path
                    d='M116.851 16.3398H111.085C100.611 16.3398 100.611 30.4369 111.085 30.4369H116.851C117.539 30.4369 118.068 29.903 118.068 29.2088V29.1554C118.068 28.4612 117.539 27.9272 116.851 27.9272H111.085C104.632 27.6602 104.632 19.1699 111.085 18.9563H116.851C117.539 18.9563 118.068 18.4224 118.068 17.7282V17.6214C118.068 16.9272 117.539 16.3398 116.851 16.3398Z'
                    fill='#64A4D5'
                />
                <path
                    d='M110.662 22.1068C109.974 22.1068 109.392 22.6942 109.392 23.3884C109.392 24.0825 109.974 24.6699 110.662 24.6699H116.428C117.116 24.6699 117.644 24.1359 117.644 23.4418V23.3884C117.644 22.6942 117.116 22.1602 116.428 22.1602H110.662V22.1068Z'
                    fill='#64A4D5'
                />
            </g>
            <defs>
                <clipPath id='clip0_763_2783'>
                    <rect width='136' height='44' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Codex;
