import * as Styled from './footer.styles';
import { Icon, ICON_TYPES } from '../../ui-kit';

const Footer = () => {
    const onEmailClick = (event) => {
        event.preventDefault();
        window.location.href = `mailto:${process.env.REACT_APP_MAIL}`;
    };

    const aqaLinkedIn = 'https://www.linkedin.com/company/2287059/';
    const onLinkedInClick = (event) => {
        event.preventDefault();
        window.open(aqaLinkedIn, '_blank');
    };

    return (
        <Styled.Footer>
            <Styled.Wrapper>
                <Styled.IconContainer>
                    <Icon type={ICON_TYPES.logo} />
                    <Styled.Label>© 2022 Codex Software</Styled.Label>
                </Styled.IconContainer>
                <Styled.SocialMediaContainer>
                    <Styled.SocialMediaIcon onClick={onEmailClick} type={ICON_TYPES.mail} />
                    <Styled.SocialMediaIcon onClick={onLinkedInClick} type={ICON_TYPES.linkedInLogo} />
                </Styled.SocialMediaContainer>
            </Styled.Wrapper>
        </Styled.Footer>
    );
};

export default Footer;
