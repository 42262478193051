import styled from 'styled-components';

export const Wrapper = styled.section`
    background: #dae9f5;
    padding: 178px 120px 147px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1200px) {
        padding: 100px 60px 80px;
    }

    @media screen and (max-width: 768px) {
        padding: 100px 30px 100px;
        flex-direction: column;
    }
`;

export const LeftContent = styled.div`
    p {
        max-width: 700px;
        letter-spacing: 0.05em
        font-weight: 500;
    }
    @media screen and (max-width: 1440px) {
        margin-bottom: 20px;
        p {
            max-width: 494px;
        }
    }
    @media screen and (max-width: 1200px) {
        p {
            font-size: 36px;
        }
    }
`;

export const RightContent = styled.div`
    img {
        max-width: 488px;
        width: 100%;
    }
`;
