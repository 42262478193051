const MessagesIcon = (props) => {
    return (
        <svg {...props} width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_1112_839)'>
                <path
                    d='M44.2384 18.8276H34.6632C34.1456 18.8276 33.7257 19.2474 33.7257 19.7651C33.7257 20.2828 34.1456 20.7026 34.6632 20.7026H44.2384C45.2787 20.7026 46.1249 21.5489 46.1249 22.5892V37.6145C46.1249 38.6547 45.2787 39.501 44.2384 39.501C43.9012 39.501 43.5899 39.6821 43.4233 39.9753L40.4711 45.1702L37.5219 40.0091C37.355 39.7171 37.0443 39.5368 36.7078 39.5368H15.9986C14.9584 39.5368 14.1122 38.6906 14.1122 37.6505C14.1488 21.9066 14.0227 22.5126 14.2193 21.9638C14.3937 21.4764 14.1401 20.9396 13.6526 20.7652C13.1656 20.5911 12.6285 20.8444 12.454 21.3319C12.3101 21.7335 12.2373 22.1566 12.2373 22.5892V37.6505C12.2373 39.7245 13.9246 41.4118 15.9986 41.4118H36.1639L39.6593 47.5287C40.0201 48.1599 40.9321 48.1535 41.2884 47.5268L44.8085 41.3329C46.6132 41.0575 48 39.4949 48 37.6145V22.5892C47.9999 20.515 46.3125 18.8276 44.2384 18.8276Z'
                    fill='#1E274F'
                />
                <path
                    d='M3.20072 22.4358C1.39172 22.1642 0 20.5995 0 18.7159V3.76251C0 1.68838 1.68741 0.000976562 3.76153 0.000976562H32.0011C34.0752 0.000976562 35.7626 1.68838 35.7626 3.76251V18.8237C35.7626 20.8978 34.0752 22.5852 32.0012 22.5852H11.8361L7.51875 30.1403L3.20072 22.4358Z'
                    fill='#BCDBF4'
                />
                <path
                    d='M43.297 32.6333H17.8811C17.3634 32.6333 16.9436 33.0531 16.9436 33.5708C16.9436 34.0885 17.3634 34.5083 17.8811 34.5083H43.2969C43.8147 34.5083 44.2344 34.0885 44.2344 33.5708C44.2345 33.0531 43.8147 32.6333 43.297 32.6333Z'
                    fill='#1E274F'
                />
                <path
                    d='M43.297 25.7305H17.8811C17.3634 25.7305 16.9436 26.1503 16.9436 26.668C16.9436 27.1858 17.3634 27.6055 17.8811 27.6055H43.2969C43.8147 27.6055 44.2344 27.1858 44.2344 26.668C44.2345 26.1502 43.8147 25.7305 43.297 25.7305Z'
                    fill='#1E274F'
                />
                <path
                    d='M17.8812 18.8196C22.0315 18.8196 25.408 15.4431 25.408 11.2929C25.408 7.14261 22.0314 3.76611 17.8812 3.76611C13.7309 3.76611 10.3545 7.14271 10.3545 11.2929C10.3545 15.4431 13.731 18.8196 17.8812 18.8196ZM17.8812 5.64111C20.9976 5.64111 23.533 8.17649 23.533 11.2929C23.533 14.4093 20.9976 16.9446 17.8812 16.9446C14.7649 16.9446 12.2295 14.4093 12.2295 11.2929C12.2295 8.17639 14.7649 5.64111 17.8812 5.64111Z'
                    fill='#1E274F'
                />
                <path
                    d='M15.9985 11.289C16.5162 11.289 16.936 10.8692 16.936 10.3515V9.41016C16.936 8.89247 16.5162 8.47266 15.9985 8.47266C15.4808 8.47266 15.061 8.89247 15.061 9.41016V10.3515C15.061 10.8692 15.4808 11.289 15.9985 11.289Z'
                    fill='#1E274F'
                />
                <path
                    d='M19.7639 11.289C20.2817 11.289 20.7014 10.8692 20.7014 10.3515V9.41016C20.7014 8.89247 20.2817 8.47266 19.7639 8.47266C19.2461 8.47266 18.8264 8.89247 18.8264 9.41016V10.3515C18.8264 10.8692 19.2462 11.289 19.7639 11.289Z'
                    fill='#1E274F'
                />
                <path
                    d='M15.8872 12.9025C15.521 13.2685 15.521 13.8621 15.8871 14.2283C16.989 15.3304 18.7731 15.3307 19.8754 14.2284C20.2415 13.8623 20.2415 13.2687 19.8754 12.9025C19.5092 12.5365 18.9158 12.5365 18.5495 12.9025C18.1801 13.2719 17.5825 13.272 17.2129 12.9026C16.847 12.5364 16.2533 12.5364 15.8872 12.9025Z'
                    fill='#1E274F'
                />
            </g>
            <defs>
                <clipPath id='clip0_1112_839'>
                    <rect width='48' height='48' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default MessagesIcon;
