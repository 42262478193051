import styled from 'styled-components';
import { Typography } from '../../ui-kit';

export const Error = styled(Typography)`
    position: absolute;
    bottom: -20px;
    left: 5px;
    color: crimson;
    letter-spacing: 0;
    font-size: 12px;
`;
