import styled from 'styled-components';
import { Typography } from '../../../../../../shared/ui-kit';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 608px;
    padding-right: 20px;

    @media (max-width: 1024px) {
        width: auto;
    }
`;

export const Title = styled(Typography)`
    font-weight: bold;
    @media (max-width: 1024px) {
        text-align: center;
    }
`;

export const List = styled.ul`
    margin-top: 32px;
    list-style: none;
    padding: 0;
`;

export const ListItem = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    &:last-child {
        margin-bottom: 0;
    }
    & > svg {
        min-width: 24px;
        height: 24px;
    }
`;

export const ListItemTitle = styled.p`
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #1e274f;
    margin-left: 8px;
`;
