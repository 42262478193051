import { Form, Field } from 'react-final-form';
import ReactGA from 'react-ga';
import { useContext } from 'react';

import * as Styled from './right-content.styles';
import {
    composeValidators,
    fieldRequired,
    maxLength,
    ToastContext,
    validateEmail,
} from '../../../../../../shared/helpers';
import { FieldError } from '../../../../../../shared/components';
import requestAQuoteService from '../../../../../../shared/services/request-a-quote.service';
import { useNavigate } from 'react-router-dom';

const FormValuesFields = {
    name: 'name',
    email: 'email',
    message: 'message',
};

const fields = [
    {
        name: FormValuesFields.name,
        placeholder: 'Your name',
        type: 'text',
        validate: fieldRequired,
    },
    {
        name: FormValuesFields.email,
        placeholder: 'Your email',
        type: 'email',
        validate: composeValidators(fieldRequired, validateEmail),
    },
    {
        isTextarea: true,
        name: FormValuesFields.message,
        placeholder: 'Type here',
        type: 'text',
        validate: composeValidators(fieldRequired, maxLength(500)),
    },
];

const RightContent = () => {
    const navigate = useNavigate();
    const onSubmit = (values) => {
        return requestAQuoteService.requestAQuote(values);
    };

    const { open } = useContext(ToastContext);

    const errorNotification = () => {
        open({ type: 'Error', content: 'Something went wrong. Please try again!' });
    };

    return (
        <Styled.Container>
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting, form }) => (
                    <form
                        onSubmit={(event) => {
                            const promise = handleSubmit(event);
                            if (promise) {
                                promise
                                    .then(() => {
                                        ReactGA.event({
                                            category: 'User',
                                            action: "User filled a 'Request a quote' and sent a request",
                                        });
                                        Object.keys(FormValuesFields).forEach((key) => {
                                            form.change(key, undefined);
                                            form.resetFieldState(key);
                                        });
                                        navigate('/thank-you', { state: { isFromApp: true } });
                                    })
                                    .catch(() => {
                                        errorNotification();
                                    });
                                return promise;
                            }
                        }}>
                        {fields.map(({ isTextarea, name, validate, type, placeholder }) => {
                            return isTextarea ? (
                                <Field name={name} validate={validate} key={name} component='textarea'>
                                    {({ input, meta }) => (
                                        <Styled.FieldBlock>
                                            <Styled.Textarea
                                                isError={meta.touched && meta.error}
                                                {...input}
                                                placeholder={placeholder}
                                            />
                                            <FieldError meta={meta} />
                                        </Styled.FieldBlock>
                                    )}
                                </Field>
                            ) : (
                                <Field name={name} validate={validate} key={name}>
                                    {({ input, meta }) => (
                                        <Styled.FieldBlock>
                                            <Styled.FieldInput
                                                isError={meta.touched && meta.error}
                                                type={type}
                                                {...input}
                                                placeholder={placeholder}
                                            />
                                            <FieldError meta={meta} />
                                        </Styled.FieldBlock>
                                    )}
                                </Field>
                            );
                        })}

                        <Styled.SubmitButton type='submit' disabled={submitting}>
                            <Styled.SubmitButtonText>Request a quote</Styled.SubmitButtonText>
                        </Styled.SubmitButton>
                    </form>
                )}
            />
        </Styled.Container>
    );
};

export default RightContent;
