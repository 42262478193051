import styled from 'styled-components';
import { Button, Icon, Typography } from '../../ui-kit';

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    background-color: #1e274f;
    backdrop-filter: blur(20px);

    @media (max-width: 1240px) {
        padding: 0 20px;
        box-sizing: border-box;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
`;

export const ContactUsButton = styled(Button)`
    width: 150px;
    height: 48px;
    @media (max-width: 479px) {
        width: 48px;
    }
`;

export const IconLogo = styled(Icon)`
    cursor: pointer;
`;

export const ButtonText = styled(Typography)`
    color: inherit;
    line-height: 21px;
    letter-spacing: 0;

    @media (max-width: 479px) {
        display: none;
    }
`;

export const ButtonIcon = styled(Icon)`
    display: none;

    @media (max-width: 479px) {
        display: block;
    }
`;
