import styled from 'styled-components';

export const RegularText = styled.p`
    margin: 0;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.4;
    color: #1e274f;
`;

export const H6Text = styled(RegularText)`
    font-weight: 700;
    line-height: 24px;
`;

export const H3Text = styled(RegularText)`
    font-weight: 500;
    font-size: 48px;
    line-height: 1.2;

    @media (max-width: 767px) {
        font-size: 40px;
        line-height: 47px;
    }

    @media (max-width: 479px) {
        font-size: 32px;
        line-height: 38px;
    }
`;

export const H1Text = styled(RegularText)`
    font-weight: 500;
    font-size: 72px;
    line-height: 1.2;
    color: #ffffff;

    @media (max-width: 767px) {
        font-size: 60px;
        line-height: 71px;
    }

    @media (max-width: 479px) {
        font-size: 40px;
        line-height: 52px;
    }
`;
