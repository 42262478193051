import { useContext } from 'react';

import * as Styled from './FindSolutionForm.styles';
import { LeftContent } from './components/left-content';
import { RightContent } from './components/right-content';
import { NavigateContext } from '../../../../shared/helpers';

const FindSolutionForm = () => {
    const { FindSolutionFormRef } = useContext(NavigateContext);

    return (
        <Styled.Container ref={FindSolutionFormRef}>
            <Styled.Wrapper>
                <LeftContent />
                <RightContent />
            </Styled.Wrapper>
        </Styled.Container>
    );
};

export default FindSolutionForm;
