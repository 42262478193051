import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import * as Styled from './header.styles';
import { ICON_TYPES } from '../../ui-kit';
import { NavigateContext, PATH } from '../../helpers';

const Header = () => {
    const navigate = useNavigate();
    const { scrollToContactUsForm } = useContext(NavigateContext);

    const goMainPageClick = () => {
        navigate(`${PATH.main}`);
    };

    const goToConactUs = () => {
        scrollToContactUsForm();
    };

    return (
        <Styled.Header>
            <Styled.Wrapper>
                <Styled.IconLogo type={ICON_TYPES.codex} onClick={goMainPageClick} />
                <Styled.ContactUsButton variant='outlined' onClick={goToConactUs} aria-label='Contact us'>
                    <Styled.ButtonText>Contact us</Styled.ButtonText>
                    <div>
                        <Styled.ButtonIcon type={ICON_TYPES.yahooMail} />
                    </div>
                </Styled.ContactUsButton>
            </Styled.Wrapper>
        </Styled.Header>
    );
};

export default Header;
