import styled from 'styled-components';
import { Typography } from '../../../../../../shared/ui-kit';

export const Wrapper = styled.div`
    position: relative;
    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background: #bcdbf4;
    }
    &:last-child:after {
        display: none;
    }
`;

export const Toggle = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    user-select: none;
`;

export const ToggleIcon = styled.img`
    min-width: 24px;
    max-width: 24px;
    user-select: none;
    transition: transform 0.3s ease-in-out;

    ${(props) =>
        props.isActive &&
        `
    transform: rotate(180deg);
  `}
`;

export const Content = styled.div`
    padding-bottom: 24px;
    height: auto;
    opacity: 1;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out;
    ${(props) =>
        !props.isActive &&
        `
    height: 0;
    opacity: 0;
    padding-bottom: 0;
  `}
`;

export const ContentItem = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    & > img {
        margin-top: 3px;
    }
`;

export const ContentItemTitle = styled(Typography)`
    margin-left: 15px;
`;

export const ContentItemHeading = styled(Typography)`
    margin-bottom: 24px;
    margin-top: 32px;
`;
