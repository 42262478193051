const DirectionsIcon = (props) => {
    return (
        <svg {...props} width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M26.6305 26.7347C26.1128 26.7347 25.693 26.315 25.693 25.7972C25.693 22.9727 27.9909 20.6748 30.8154 20.6748H34.2341C34.749 20.6748 35.1675 21.0901 35.1716 21.6051C35.1754 22.1085 35.1782 22.841 35.1801 23.8237L41.7493 18.9819L35.1707 14.1345C35.1666 14.9826 35.1631 15.7172 35.1602 16.3556C35.1579 16.8717 34.7388 17.2888 34.2227 17.2888H30.8154C28.5506 17.2888 26.416 18.1759 24.8051 19.7869C24.4389 20.1531 23.8454 20.153 23.4792 19.7869C23.1131 19.4208 23.1131 18.8272 23.4792 18.4611C26.431 15.565 29.3777 15.2869 33.2896 15.4139C33.2938 14.5319 33.2989 13.4978 33.3048 12.2814C33.2913 11.5343 34.2056 11.0718 34.7984 11.5313L43.8857 18.2272C44.1256 18.4039 44.2671 18.684 44.2671 18.9819C44.2671 19.2797 44.1255 19.5599 43.8858 19.7366L34.8011 26.4324C34.2084 26.8905 33.2896 26.4303 33.3074 25.6783C33.3066 24.3564 33.3048 23.3215 33.3017 22.5499H30.8155C29.0248 22.5499 27.5681 24.0067 27.5681 25.7973C27.568 26.315 27.1483 26.7347 26.6305 26.7347Z'
                fill='#1E274F'
            />
            <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M27.5872 17.3328V9.10744L30.6959 9.08728L24.0001 0L17.3042 9.08484L20.413 9.08728V28.0954C23.9811 29.4089 26.4851 32.7427 26.6247 36.6413C26.937 36.7718 27.258 36.8827 27.5872 36.9709C27.5872 32.1462 27.5872 22.0983 27.5872 17.3328Z'
                fill='#BCDBF4'
            />
            <path
                d='M17.1846 26.5873H14.7105C14.7063 25.7054 14.7011 24.6712 14.6952 23.4548C14.7087 22.7077 13.7944 22.2451 13.2016 22.7047L4.11428 29.4005C3.87438 29.5772 3.73291 29.8574 3.73291 30.1552C3.73291 30.4531 3.87447 30.7332 4.11419 30.9099L13.199 37.6058C13.7922 38.0638 14.7103 37.6037 14.6927 36.8517C14.6934 35.5307 14.6952 34.4957 14.6983 33.7233H17.1845C18.9752 33.7233 20.4319 35.1801 20.4319 36.9707V47.0624C20.4319 47.5802 20.8518 47.9999 21.3694 47.9999H26.6305C27.1482 47.9999 27.568 47.5802 27.568 47.0624V36.9707C27.5681 31.2452 22.9101 26.5873 17.1846 26.5873ZM25.6931 46.1249H22.307V36.9707C22.307 34.1462 20.0091 31.8482 17.1846 31.8482H13.7659C13.251 31.8482 12.8325 32.2636 12.8284 32.7785C12.8246 33.2833 12.8218 34.0161 12.8199 34.9971L6.25075 30.1553L12.8293 25.308C12.8334 26.156 12.837 26.8907 12.8399 27.529C12.8422 28.0451 13.2613 28.4622 13.7774 28.4622H17.1846C21.8763 28.4622 25.6931 32.279 25.6931 36.9706V46.1249Z'
                fill='#1E274F'
            />
        </svg>
    );
};

export default DirectionsIcon;
