import * as Styled from './ThankYouContent.styles';
import ThankYouImage from '../../../assets/images/thank-you.png';
import { ICON_TYPES } from '../../../shared/ui-kit';

const ThankYouContent = () => {
    return (
        <Styled.Container>
            <Styled.Image width={440} height={399} src={ThankYouImage} alt='Thank You' />
            <Styled.Text>
                Thank you for reaching out. Our sales representative will get back to you soon. You can download the
                introduction presentation about Codex from the link below.
            </Styled.Text>
            <Styled.Link to='/files/Codex Software AQA.pdf' target='_blank' download>
                <Styled.DownloadButton icon={ICON_TYPES.download}>
                    <Styled.DownloadButtonText>Download Now</Styled.DownloadButtonText>
                </Styled.DownloadButton>
            </Styled.Link>
        </Styled.Container>
    );
};

export default ThankYouContent;
