import * as Styled from './case-studies.styles';
import { Typography } from '../../ui-kit';
import { Cards } from '../index';

const CaseStudies = ({ fullWidthTitle, title, cardId = 0 }) => {
    return (
        <Styled.CaseStudiesContainer>
            <Styled.CaseStudiesContent>
                <Typography type='h3' style={{ width: fullWidthTitle ? '100%' : null }}>
                    {title}
                </Typography>
                <Cards cardId={cardId} />
            </Styled.CaseStudiesContent>
        </Styled.CaseStudiesContainer>
    );
};

export default CaseStudies;
