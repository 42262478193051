import { Route, Routes } from 'react-router-dom';

import { Introduction } from '../components/introduction';
import { HowHelps } from '../components/how-helps';
import { ScrollToTop } from '../../../shared/components';
import { ProvideQualityInfo } from '../components/provide-quality-info';
import { FindSolutionForm } from '../components/find-solution-form';
import { ICON_TYPES } from '../../../shared/ui-kit';
import { Helmet } from 'react-helmet';

const Landing = () => {
    const whyUsCardsContent = [
        {
            icon: ICON_TYPES.moneyBag,
            title: 'Save money',
            description:
                'The hourly rates of our AQA personnell are 50-60% lower than those of the in-house engineers.',
        },
        {
            icon: ICON_TYPES.timer,
            title: 'Hire Faster',
            description:
                'Our Automated Quality Assurance engineers are available within a month since the signing of a contract.',
        },
        {
            icon: ICON_TYPES.messages,
            title: 'Flawless Communication',
            description:
                'All our Automated Quality Assurance engineers are located in European timezones and have at least a B2 English.',
        },
        {
            icon: ICON_TYPES.directions,
            title: 'Flexibility',
            description: 'Staff as many MQA and AQA engineers as needed and scale the team up and down at ease.',
        },
        {
            icon: ICON_TYPES.balance,
            title: 'No Hustle',
            description:
                'All the HR management, dealing with retention, vacations, sick leaves, social security, etc. are on us.',
        },
        {
            icon: ICON_TYPES.support,
            title: 'Extra Services',
            description:
                'As a software company, we provide full range of IT specialists on demand, including front- and back-end developers, designers, DevOps engineers and managers.',
        },
    ];
    return (
        <Routes>
            <Route
                path='/'
                element={
                    <>
                        <Helmet>
                            <meta
                                name='description'
                                content='We provide quality assurance engineers for software projects of any complexity and scale.'
                            />
                            <meta
                                itemprop='description'
                                content='We provide quality assurance engineers for software projects of any complexity and scale.'
                            />
                            <meta
                                name='og:description'
                                content='We provide quality assurance engineers for software projects of any complexity and scale.'
                            />
                            <meta
                                name='twitter:description'
                                content='We provide quality assurance engineers for software projects of any complexity and scale.'
                            />
                        </Helmet>
                        <ScrollToTop />
                        <Introduction />
                        <ProvideQualityInfo />
                        <HowHelps cardsContent={whyUsCardsContent} />
                        <FindSolutionForm />
                    </>
                }
            />
        </Routes>
    );
};

export default Landing;
