import { createContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PATH from './app.path';

export const NavigateContext = createContext(null);

const NavigateContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const contactUsRef = useRef(null);
    const bookCallRef = useRef(null);
    const FindSolutionFormRef = useRef(null);

    const scrollToContactUsForm = () => {
        if (contactUsRef.current) {
            contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            navigate(PATH.main);
            setTimeout(() => {
                contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
            }, 200);
        }
    };

    const scrollToBookCallForm = () => {
        bookCallRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToFindSolutionForm = () => {
        FindSolutionFormRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const value = {
        contactUsRef,
        bookCallRef,
        scrollToContactUsForm,
        scrollToBookCallForm,
        FindSolutionFormRef,
        scrollToFindSolutionForm,
    };

    return <NavigateContext.Provider value={value}>{children}</NavigateContext.Provider>;
};

export default NavigateContextProvider;
