import * as Styled from './button.styles';

const variantStyledMap = {
    contained: Styled.ButtonContainer,
    outlined: Styled.OutlinedContainer,
};

const Button = ({ variant = 'contained', icon = '', children, ...props }) => {
    const Container = variantStyledMap[variant];

    return (
        <Container {...props}>
            {icon && <Styled.ButtonIcon type={icon} />}
            {children}
        </Container>
    );
};

export default Button;
