import styled from 'styled-components';
import { Typography } from '../../ui-kit';

export const Container = styled.section`
    padding-bottom: 150px;
    box-sizing: border-box;
    background-color: #f5f5f5;
    overflow-x: hidden;

    @media (max-width: 1240px) {
        padding: 0 20px 75px 20px;
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 120px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    @media screen and (max-width: 1240px) {
        flex-direction: column;
    }

    @media screen and (max-width: 768px) {
        padding: 0 40px;
    }

    @media screen and (max-width: 479px) {
        padding: 0 20px;
    }
`;

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
    align-self: flex-end;

    div:first-child {
        margin-top: 0;
    }

    @media screen and (max-width: 1240px) {
        width: 100%;
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }

    @media (max-width: 479px) {
        align-items: flex-start;
    }
`;

export const LeftSide = styled.div`
    width: 558px;
    @media (max-width: 1024px) {
        width: 100%;
    }
    @media (max-width: 479px) {
        width: auto;
    }
`;
export const RightSide = styled(LeftSide)`
    margin-left: 50px;
    @media (max-width: 1240px) {
        align-self: center;
        margin-left: 0;
    }
`;

export const Title = styled(Typography)`
    margin-bottom: 12px;
    @media (max-width: 1024px) {
        margin-bottom: 24px;
    }

    @media (max-width: 767px) {
        font-size: 48px;
        line-height: 57px;
    }

    @media (max-width: 479px) {
        font-size: 48px;
        line-height: 57px;
    }
`;

export const IconBlock = styled.div`
    display: flex;
    align-items: center;
    margin-top: 24px;

    svg:nth-child(even) {
        margin-left: 32px;
    }

    @media (max-width: 479px) {
        flex-direction: column;
        align-items: flex-start;
        svg:nth-child(even) {
            margin-top: 48px;
            margin-left: 0px;
        }
    }
`;

export const List = styled.ol`
    margin: 0;
    padding: 0 20px;
`;

export const AdditionalDescription = styled(Typography)`
    margin-top: 48px;
`;

export const Item = styled.li`
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #1e274f;
`;
