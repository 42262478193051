/* eslint-disable react-hooks/exhaustive-deps */
import * as Styled from './thank-you-page.styles';
import ThankYouContent from './ThankYouContent/ThankYouContent';
import OurClientsSay from './OurClientsSay/OurClientsSay';
import CaseStudies from '../../shared/components/case-studies/CaseStudies';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ThankYouPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (!location.state?.isFromApp) navigate('/');
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Styled.Container>
            <Helmet>
                <meta
                    name='description'
                    content='Thank you for reaching out. Our sales representative will get back to you soon. You can download the introduction presentation about Codex from the link below.'
                />
                <meta
                    itemprop='description'
                    content='Thank you for reaching out. Our sales representative will get back to you soon. You can download the introduction presentation about Codex from the link below.'
                />
                <meta
                    name='og:description'
                    content='Thank you for reaching out. Our sales representative will get back to you soon. You can download the introduction presentation about Codex from the link below.'
                />
                <meta
                    name='twitter:description'
                    content='Thank you for reaching out. Our sales representative will get back to you soon. You can download the introduction presentation about Codex from the link below.'
                />
            </Helmet>
            <ThankYouContent />
            <OurClientsSay />
            <CaseStudies fullWidthTitle title='More success stories to check...' />
        </Styled.Container>
    );
};

export default ThankYouPage;
