import styled from 'styled-components';
import { Icon } from '../icon';

export const ButtonContainer = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e274f;
    color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    height: 64px;
    cursor: pointer;
    width: 200px;
    transition: 0.3s ease-in-out;

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        user-select: none;
        pointer-events: none;
    }

    &:hover {
        background-color: #0a1133;
        box-shadow: 0 0 0 3px #1e274f;
    }
`;

export const OutlinedContainer = styled(ButtonContainer)`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #64a4d5;
    width: 150px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #64a4d5;
    position: relative;
    overflow: hidden;

    &:hover {
        background-color: transparent;
        border: 1px solid #215c8a;
        color: #fff;
        box-shadow: 0 0 0 2px #64a4d5;
        &:before {
            width: 100%;
            height: 100%;
            opacity: 1;
        }
    }

    &:before {
        position: absolute;
        content: '';
        background: #215c8a;
        transition: 0.2s ease-in-out;
        z-index: -1;
        inset: auto auto 0px 0px;
        width: 100%;
        height: 0px;
        opacity: 1;
    }
`;

export const ButtonIcon = styled(Icon)`
    padding-right: 10px;
`;
