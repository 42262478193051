import React from 'react';
import { RegularText, H3Text, H1Text, H6Text } from './typography.styles';

const getTypographyElementByType = (type) => {
    switch (type) {
        case 'h1':
            return H1Text;
        case 'h3':
            return H3Text;
        case 'h6':
            return H6Text;
        default:
            return RegularText;
    }
};

const Typography = ({ type = 'regular-text', children, ...props }) => {
    const TypographyElement = getTypographyElementByType(type);
    return <TypographyElement {...props}>{children}</TypographyElement>;
};

export default Typography;
