const Timer = (props) => {
    return (
        <svg {...props} width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M37.8384 20.7056C40.0969 25.1227 40.0975 30.4068 37.8384 34.8253H10.1616C7.90312 30.4082 7.90237 25.1242 10.1616 20.7056H37.8384Z'
                fill='#BCDBF4'
            />
            <path
                d='M24 11.296C14.9189 11.296 7.53094 18.684 7.53094 27.765C7.53094 36.8466 14.9189 44.235 24 44.235C33.0811 44.235 40.4691 36.8466 40.4691 27.765C40.4691 18.684 33.0811 11.296 24 11.296V11.296ZM24 42.36C15.9529 42.36 9.40594 35.8127 9.40594 27.765C9.40594 19.7178 15.9528 13.171 24 13.171C32.0472 13.171 38.5941 19.7178 38.5941 27.765C38.5941 35.8127 32.0471 42.36 24 42.36V42.36Z'
                fill='#1E274F'
            />
            <path
                d='M40.4469 15.9723L40.9706 15.4487C42.2538 14.1655 42.2538 12.0778 40.9706 10.7947C39.6878 9.51159 37.5998 9.51169 36.3167 10.7947L35.793 11.3182C33.1356 9.40425 30.0713 8.17631 26.8202 7.72566V5.64028H28.236C28.7538 5.64028 29.1735 5.22047 29.1735 4.70278V0.9375C29.1735 0.419719 28.7538 0 28.236 0H19.7641C19.2463 0 18.8266 0.419719 18.8266 0.9375V4.70278C18.8266 5.22047 19.2463 5.64028 19.7641 5.64028H21.1799V7.72566C17.9288 8.17631 14.8645 9.40425 12.2071 11.3183L11.6835 10.7947C10.4003 9.51159 8.31263 9.51159 7.02957 10.7947C5.74651 12.0777 5.74651 14.1655 7.02957 15.4486L7.55316 15.9723C5.09354 19.3872 3.76538 23.4739 3.76538 27.7653C3.76529 38.9484 12.8153 48 24 48C35.1831 48 44.2347 38.95 44.2347 27.7653C44.2347 23.4739 42.9066 19.3872 40.4469 15.9723V15.9723ZM37.6424 12.1206C38.1944 11.5685 39.0926 11.5683 39.6447 12.1206C40.1968 12.6726 40.1968 13.5709 39.6448 14.1228L39.2743 14.4932C38.6434 13.7692 37.9964 13.1222 37.272 12.4909L37.6424 12.1206ZM20.7015 1.875H27.2984V3.76528H20.7015V1.875ZM23.0548 5.64028H24.9452V7.55278C24.3055 7.52363 23.7122 7.52278 23.0548 7.55278V5.64028ZM8.35529 14.1229C7.80319 13.5709 7.80319 12.6726 8.35529 12.1207C8.90719 11.5686 9.80541 11.5685 10.3575 12.1206L10.7279 12.491C10.0041 13.1218 9.35691 13.7689 8.7256 14.4933L8.35529 14.1229ZM24 46.125C13.8764 46.125 5.64029 37.8888 5.64029 27.7653C5.64029 17.6418 13.8764 9.40556 24 9.40556C34.1236 9.40556 42.3597 17.6417 42.3597 27.7653C42.3597 37.8889 34.1236 46.125 24 46.125Z'
                fill='#1E274F'
            />
            <path
                d='M26.3533 24.0038H25.4121C24.8943 24.0038 24.4746 24.4237 24.4746 24.9413C24.4746 25.4591 24.8943 25.8788 25.4121 25.8788H25.4158V30.5892C25.4158 31.1069 25.8355 31.5267 26.3533 31.5267C26.8711 31.5267 27.2908 31.1069 27.2908 30.5892V24.9413C27.2908 24.4237 26.871 24.0038 26.3533 24.0038V24.0038Z'
                fill='#1E274F'
            />
            <path
                d='M15.9987 24.0038C14.4437 24.0038 13.1785 25.1284 13.1785 26.5105V29.0201C13.1785 30.4023 14.4437 31.5267 15.9987 31.5267C17.5538 31.5267 18.8188 30.4023 18.8188 29.0201V26.5105C18.8188 25.1284 17.5538 24.0038 15.9987 24.0038ZM16.9438 29.0201C16.9438 29.3188 16.5557 29.6517 15.9987 29.6517C15.4418 29.6517 15.0535 29.3188 15.0535 29.0201V26.5105C15.0535 26.2117 15.4418 25.8788 15.9987 25.8788C16.5557 25.8788 16.9438 26.2118 16.9438 26.5105V29.0201Z'
                fill='#1E274F'
            />
            <path
                d='M32.0013 24.0038C30.4463 24.0038 29.1812 25.1284 29.1812 26.5105V29.0201C29.1812 30.4023 30.4463 31.5267 32.0013 31.5267C33.5563 31.5267 34.8215 30.4023 34.8215 29.0201V26.5105C34.8215 25.1284 33.5563 24.0038 32.0013 24.0038ZM32.9465 29.0201C32.9465 29.3188 32.5583 29.6517 32.0013 29.6517C31.4443 29.6517 31.0562 29.3188 31.0562 29.0201V26.5105C31.0562 26.2117 31.4443 25.8788 32.0013 25.8788C32.5583 25.8788 32.9465 26.2118 32.9465 26.5105V29.0201Z'
                fill='#1E274F'
            />
            <path
                d='M22.5111 29.9902C22.7079 29.5113 22.4791 28.9635 22.0002 28.7668C21.5212 28.57 20.9735 28.7988 20.7767 29.2777C20.58 29.7566 20.8087 30.3044 21.2877 30.5011C21.7666 30.6979 22.3144 30.4691 22.5111 29.9902Z'
                fill='#1E274F'
            />
            <path
                d='M21.8395 26.8055C22.347 26.7019 22.6743 26.2066 22.5708 25.6992C22.4672 25.1918 21.972 24.8644 21.4645 24.968C20.9571 25.0715 20.6297 25.5668 20.7333 26.0742C20.8368 26.5816 21.3321 26.909 21.8395 26.8055Z'
                fill='#1E274F'
            />
        </svg>
    );
};

export default Timer;
