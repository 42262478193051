import styled from 'styled-components';

export const BookCallContainer = styled.section`
    background-color: #dae9f5;
    padding: 65px 20px;
    box-sizing: border-box;
`;

export const BookCallContent = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    width: auto;
    margin: 0 auto;

    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
    }
`;
