import styled from 'styled-components';
import background from './background.webp';
import { Button, Typography } from '../../../../shared/ui-kit';

export const IntroductionContainer = styled.section`
    background: url(${background});
    background-size: cover;
    height: calc(100vh - 80px);

    @media (max-width: 1240px) {
        padding: 0 20px;
        box-sizing: border-box;
    }

    @media (max-width: 767px) {
        padding: 69px 20px 38px 20px;
        height: 100%;
    }
`;

export const IntroductionContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1200px;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    @media (max-width: 767px) {
        align-items: flex-start;
    }

    @media (max-width: 479px) {
        align-items: center;
    }
`;

export const TitleBlock = styled.div`
    width: 800px;

    @media (max-width: 1024px) {
        width: auto;
    }
`;

export const Advantages = styled(Typography)`
    margin-top: 24px;
    color: #64a4d5;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
`;

export const DescriptionBlock = styled.div`
    margin-top: 24px;
    width: 760px;

    @media (max-width: 767px) {
        width: auto;
    }
`;

export const Description = styled(Typography)`
    color: #ffffff;
`;

export const BookCallButton = styled(Button)`
    margin-top: 48px;
    width: 210px;
    height: 64px;
    background: white;
    color: #1e274f;
    filter: drop-shadow(0px 24px 48px rgba(188, 219, 244, 0.12));
    &:hover {
        background: white;
        color: #1e274f;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
    }

    @media (max-width: 479px) {
        width: 335px;
    }
    & > svg {
        filter: invert(1);
    }
`;
export const BookCallButtonText = styled(Typography)`
    line-height: 24px;
`;
