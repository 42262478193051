import { memo } from 'react';

import * as Styled from './second-section.styles';
import { Icon, Typography } from '../../ui-kit';
import { FreeConsultation } from '../free-consultation';

const SecondSection = ({ content = [] }) => {
    return (
        <Styled.Container>
            <Styled.Content>
                <Styled.LeftSide>
                    {content.map(({ description, icon, title, additionalDescription }) => (
                        <Styled.Block key={title}>
                            <Styled.Title type='h3'>{title}</Styled.Title>
                            {icon.length > 0 && (
                                <Styled.IconBlock>
                                    {icon.map((i) => (
                                        <Icon type={i} key={i} />
                                    ))}
                                </Styled.IconBlock>
                            )}
                            {!Array.isArray(description) ? (
                                <>
                                    <Typography>{description}</Typography>
                                    {additionalDescription && (
                                        <Styled.AdditionalDescription>
                                            {additionalDescription}
                                        </Styled.AdditionalDescription>
                                    )}
                                </>
                            ) : (
                                <Styled.List>
                                    {description.map((item) => (
                                        <Styled.Item key={item}>{item}</Styled.Item>
                                    ))}
                                </Styled.List>
                            )}
                        </Styled.Block>
                    ))}
                </Styled.LeftSide>
                <Styled.RightSide>
                    <Styled.Block>
                        <FreeConsultation />
                    </Styled.Block>
                </Styled.RightSide>
            </Styled.Content>
        </Styled.Container>
    );
};

export default memo(SecondSection);
