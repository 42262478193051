import styled from 'styled-components';
import { Button, Input, Typography } from '../../../../ui-kit';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FieldBlock = styled.div`
    position: relative;
`;

export const FieldInput = styled(Input)`
    margin-top: 24px;

    @media (max-width: 479px) {
        width: 335px;
    }
`;

export const SubmitButton = styled(Button)`
    width: 418px;
    height: 64px;
    margin-top: 48px;
    @media (max-width: 479px) {
        width: 335px;
    }
`;

export const SubmitButtonText = styled(Typography)`
    color: #ffffff;
    letter-spacing: 0;
`;
