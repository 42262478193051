const LinkedInLogo = (props) => {
    return (
        <svg {...props} width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M16.0001 0.640015C7.51694 0.640015 0.640137 7.51682 0.640137 16C0.640137 24.4832 7.51694 31.36 16.0001 31.36C24.4833 31.36 31.3601 24.4832 31.3601 16C31.3601 7.51682 24.4833 0.640015 16.0001 0.640015ZM12.2401 22.3664H9.12974V12.3568H12.2401V22.3664ZM10.6657 11.128C9.68334 11.128 9.04814 10.432 9.04814 9.57121C9.04814 8.69281 9.70254 8.01761 10.7057 8.01761C11.7089 8.01761 12.3233 8.69281 12.3425 9.57121C12.3425 10.432 11.7089 11.128 10.6657 11.128ZM23.6001 22.3664H20.4897V16.8192C20.4897 15.528 20.0385 14.6512 18.9137 14.6512C18.0545 14.6512 17.5441 15.2448 17.3185 15.816C17.2353 16.0192 17.2145 16.3072 17.2145 16.5936V22.3648H14.1025V15.5488C14.1025 14.2992 14.0625 13.2544 14.0209 12.3552H16.7233L16.8657 13.7456H16.9281C17.3377 13.0928 18.3409 12.1296 20.0193 12.1296C22.0657 12.1296 23.6001 13.5008 23.6001 16.448V22.3664Z'
                fill='#64A4D5'
            />
        </svg>
    );
};

export default LinkedInLogo;
