import { Form, Field } from 'react-final-form';
import ReactGA from 'react-ga';
import { useContext } from 'react';

import * as Styled from './right-content.styles';
import { composeValidators, fieldRequired, ToastContext, validateEmail } from '../../../../helpers';
import { FieldError } from '../../../field-error';
import { ContactUsService } from '../../../../services';
import { useNavigate } from 'react-router-dom';

const FormValuesFields = {
    name: 'name',
    email: 'email',
    company: 'company',
};

const fields = [
    {
        name: FormValuesFields.name,
        placeholder: 'Name',
        type: 'text',
        validate: fieldRequired,
    },
    {
        name: FormValuesFields.email,
        placeholder: 'Email',
        type: 'email',
        validate: composeValidators(fieldRequired, validateEmail),
    },
    {
        name: FormValuesFields.company,
        placeholder: 'Company',
        type: 'text',
        validate: () => {},
    },
];

const RightContent = () => {
    const navigate = useNavigate();

    const onSubmit = (values) => {
        return ContactUsService.contactUs(values);
    };

    const { open } = useContext(ToastContext);

    const errorNotification = () => {
        open({ type: 'Error', content: 'Something went wrong. Please try again!' });
    };

    return (
        <Styled.Container>
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting, form }) => (
                    <form
                        onSubmit={(event) => {
                            const promise = handleSubmit(event);
                            if (promise) {
                                promise
                                    .then(() => {
                                        ReactGA.event({
                                            category: 'User',
                                            action: "User has filled a 'Contact us form' and sent a request",
                                        });
                                        Object.keys(FormValuesFields).forEach((key) => {
                                            form.change(key, undefined);
                                            form.resetFieldState(key);
                                        });
                                        navigate('/thank-you', { state: { isFromApp: true } });
                                    })
                                    .catch(() => {
                                        errorNotification();
                                    });
                                return promise;
                            }
                        }}>
                        {fields.map(({ name, validate, type, placeholder }) => (
                            <Field name={name} validate={validate} key={name}>
                                {({ input, meta }) => (
                                    <Styled.FieldBlock>
                                        <Styled.FieldInput
                                            isError={meta.touched && meta.error}
                                            type={type}
                                            {...input}
                                            placeholder={placeholder}
                                        />
                                        <FieldError meta={meta} />
                                    </Styled.FieldBlock>
                                )}
                            </Field>
                        ))}

                        <Styled.SubmitButton type='submit' disabled={submitting}>
                            <Styled.SubmitButtonText>Contact us</Styled.SubmitButtonText>
                        </Styled.SubmitButton>
                    </form>
                )}
            />
        </Styled.Container>
    );
};

export default RightContent;
