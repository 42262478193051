const CypressLogo = (props) => {
    return (
        <svg {...props} width='159' height='64' viewBox='0 0 159 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_765_4434)'>
                <path
                    d='M1.2 40.5333C0.4 37.7333 0 34.9333 0 32C0 18.9333 8.26667 6.93333 20.5333 2.13333L22.1333 6.26666C11.6 10.4 4.4 20.6667 4.4 32C4.4 34.5333 4.66667 36.9333 5.33333 39.3333L1.2 40.5333Z'
                    fill='#69D3A7'
                />
                <path
                    d='M56.6667 19.3333C51.8667 10.1333 42.4 4.4 32.1334 4.4C28.5334 4.4 24.9334 5.06667 21.6 6.53333L19.8667 2.4C23.7334 0.8 27.8667 0 32.1334 0C44.1334 0 55.0667 6.66667 60.6667 17.2L56.6667 19.3333Z'
                    fill='url(#paint0_linear_765_4434)'
                />
                <path
                    d='M22.5334 25.3333C25.0667 25.3333 27.2 26.6667 28.2667 29.0667L28.4 29.2L32.8 27.7333L32.6667 27.4667C30.9334 23.3333 27.0667 20.8 22.5334 20.8C19.3334 20.8 16.6667 21.8667 14.5334 24C12.4 26.1333 11.3334 28.8 11.3334 31.8667C11.3334 34.9333 12.4 37.6 14.5334 39.7333C16.6667 41.8667 19.3334 42.9333 22.5334 42.9333C27.0667 42.9333 30.9334 40.4 32.6667 36.2667L32.8 36L28.4 34.5333L28.2667 34.6667C27.3334 37.0667 25.2 38.4 22.5334 38.4C20.6667 38.4 19.2 37.7333 18 36.5333C16.8 35.3333 16.1334 33.7333 16.1334 31.8667C16.1334 30 16.8 28.4 18 27.0667C19.2 26 20.6667 25.3333 22.5334 25.3333Z'
                    fill='#1B1E2E'
                />
                <path
                    d='M76.1334 24.1333C74.1334 22 71.6001 20.9333 68.6667 20.9333C65.7334 20.9333 63.3334 22 61.4667 24V21.3333H56.8V50.6666H61.4667V39.8667C63.3334 41.8667 65.7334 42.9333 68.6667 42.9333C71.6001 42.9333 74.1334 41.8666 76.1334 39.7333C78.1334 37.6 79.0667 35.0667 79.0667 32C79.0667 28.9333 78.1334 26.2667 76.1334 24.1333ZM72.4001 36.6667C71.2001 37.8667 69.7334 38.5333 67.8667 38.5333C66.0001 38.5333 64.5334 37.8667 63.3334 36.6667C62.1334 35.4667 61.4667 33.8667 61.4667 32C61.4667 30.1333 62.1334 28.5333 63.3334 27.2C64.5334 26 66.0001 25.3333 67.8667 25.3333C69.7334 25.3333 71.2001 26 72.4001 27.2C73.6 28.4 74.1334 30 74.1334 32C74.2667 33.8667 73.6 35.4667 72.4001 36.6667Z'
                    fill='#1B1E2E'
                />
                <path
                    d='M86.6667 24V21.4667H82V42.5333H86.6667V30.6667C86.6667 28.8 87.4667 27.3333 88.9333 26.2667C90.4 25.3333 92.2667 25.0667 94.4 25.7333L94.6667 25.8667V21.3333C91.6 20.5333 88.6667 21.6 86.6667 24Z'
                    fill='#1B1E2E'
                />
                <path
                    d='M107.067 21.0667C104 21.0667 101.333 22.1333 99.2 24.2667C97.0667 26.2667 96 28.9333 96 32C96 35.0667 97.0667 37.7333 99.2 39.8667C101.333 42 104 43.0667 107.2 43.0667C111.6 43.0667 115.333 40.8 117.2 37.2L117.333 36.9333L113.333 35.2L113.2 35.3333C112 37.6 109.867 38.9333 107.333 38.9333C104 38.9333 101.467 36.9333 100.8 33.7333H117.867V33.4667C118.267 30 117.333 27.0667 115.2 24.6667C113.2 22.2667 110.4 21.0667 107.067 21.0667ZM100.933 29.8667C101.733 26.9333 104.133 25.2 106.933 25.2C109.867 25.2 112.267 27.0667 113.067 29.8667H100.933Z'
                    fill='#1B1E2E'
                />
                <path
                    d='M130.4 30C127.867 29.4667 125.6 29.0667 125.6 27.3333C125.6 25.8667 127.067 24.9333 129.2 24.9333C131.6 24.9333 132.933 26 133.2 28V28.2667L137.867 27.3333V27.0667C137.2 23.3333 133.867 21.0667 129.2 21.0667C126.8 21.0667 124.8 21.7333 123.2 22.9333C121.6 24.1333 120.8 25.7333 120.8 27.6C120.8 30.4 122.533 32.4 125.467 33.0667C126.267 33.2 127.067 33.3333 127.867 33.4667C130.8 34 133.467 34.5333 133.467 36.4C133.467 37.8667 131.733 38.9333 129.2 38.9333C126.667 38.9333 125.2 37.8667 124.933 35.8667V35.6L120.267 36.5333V36.8C120.933 40.5333 124.267 42.8 129.333 42.8C131.867 42.8 134 42.1333 135.733 40.9333C137.467 39.7333 138.4 38.1333 138.4 36.2667C138.267 31.4667 134 30.6667 130.4 30Z'
                    fill='#1B1E2E'
                />
                <path
                    d='M150.8 30C148.267 29.4667 146 29.0667 146 27.3333C146 25.8667 147.467 24.9333 149.6 24.9333C152 24.9333 153.333 26 153.6 28V28.2667L158.267 27.3333V27.0667C157.6 23.3333 154.267 21.0667 149.6 21.0667C147.2 21.0667 145.2 21.7333 143.6 22.9333C142 24.1333 141.2 25.7333 141.2 27.6C141.2 30.4 142.933 32.4 145.867 33.0667C146.667 33.2 147.467 33.3333 148.267 33.4667C151.2 34 153.867 34.5333 153.867 36.4C153.867 37.8667 152.133 38.9333 149.6 38.9333C147.067 38.9333 145.6 37.8667 145.333 35.8667V35.6L140.667 36.5333V36.8C141.333 40.5333 144.667 42.8 149.733 42.8C152.267 42.8 154.4 42.1333 156.133 40.9333C157.867 39.7333 158.8 38.1333 158.8 36.2667C158.667 31.4667 154.267 30.6667 150.8 30Z'
                    fill='#1B1E2E'
                />
                <path
                    d='M49.7334 21.4667L43.6001 37.2L37.3334 21.4667H32.2667L41.0667 42.9333L34.6667 57.8667L39.0667 58.8L54.4001 21.4667H49.7334Z'
                    fill='#1B1E2E'
                />
                <path
                    d='M35.4667 56L34.4 58.6667C34.1334 59.2 33.7334 59.6 33.2001 59.6C32.8 59.6 32.4 59.6 32.1334 59.6C19.3334 59.6 8.00005 50.5333 5.20005 38.1333L0.800049 39.0667C2.40005 46 6.26672 52.4 11.8667 56.9333C17.6 61.4667 24.6667 64 32 64C32.4 64 32.8 64 33.3334 64C35.6 63.8667 37.7334 62.5333 38.5334 60.4L40 56.9333L35.4667 56Z'
                    fill='url(#paint1_linear_765_4434)'
                />
            </g>
            <defs>
                <linearGradient
                    id='paint0_linear_765_4434'
                    x1='28.7566'
                    y1='-2.078'
                    x2='53.1099'
                    y2='23.9509'
                    gradientUnits='userSpaceOnUse'>
                    <stop offset='0.0939' stopColor='#58D09E' />
                    <stop offset='0.9883' stopColor='#58D09E' stopOpacity='0' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_765_4434'
                    x1='-0.340084'
                    y1='46.5552'
                    x2='46.1859'
                    y2='58.9388'
                    gradientUnits='userSpaceOnUse'>
                    <stop offset='0.0774' stopColor='#58D09E' />
                    <stop offset='0.7617' stopColor='#1B1E2E' />
                </linearGradient>
                <clipPath id='clip0_765_4434'>
                    <rect width='158.667' height='64' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CypressLogo;
