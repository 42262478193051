const WhitePhone = (props) => {
    return (
        <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M19.95 21C20.25 21 20.5 20.9 20.7 20.7C20.9 20.5 21 20.25 21 19.95V15.9C21 15.6667 20.925 15.4623 20.775 15.287C20.625 15.1123 20.4333 15 20.2 14.95L16.75 14.25C16.5167 14.2167 16.279 14.2373 16.037 14.312C15.7957 14.3873 15.6 14.5 15.45 14.65L13.1 17C11.8333 16.2333 10.675 15.325 9.625 14.275C8.575 13.225 7.7 12.1 7 10.9L9.4 8.45C9.55 8.3 9.646 8.129 9.688 7.937C9.72933 7.74567 9.73333 7.53333 9.7 7.3L9.05 3.8C9.01667 3.56667 8.90833 3.375 8.725 3.225C8.54167 3.075 8.33333 3 8.1 3H4.05C3.75 3 3.5 3.1 3.3 3.3C3.1 3.5 3 3.75 3 4.05C3 6.2 3.47933 8.29567 4.438 10.337C5.396 12.379 6.66267 14.1873 8.238 15.762C9.81267 17.3373 11.6207 18.604 13.662 19.562C15.704 20.5207 17.8 21 19.95 21Z'
                fill='white'
            />
        </svg>
    );
};

export default WhitePhone;
