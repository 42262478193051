import * as Styled from './how-helps.styles';
import { Icon, Typography } from '../../../../shared/ui-kit';

const HowHelps = (props) => {
    return (
        <Styled.Container>
            <Styled.TitleBlock>
                <Typography type='h3'>Why use Automated Testing?</Typography>
            </Styled.TitleBlock>
            <Styled.Cards>
                {props.cardsContent.map(({ icon, description, title }) => (
                    <Styled.Card key={title}>
                        <Icon type={icon} />
                        <Styled.CardTitle type='h6'>{title}</Styled.CardTitle>
                        <Styled.CardDescription>{description}</Styled.CardDescription>
                    </Styled.Card>
                ))}
            </Styled.Cards>
        </Styled.Container>
    );
};

export default HowHelps;
